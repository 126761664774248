import React from 'react';
import {Route} from "react-router-dom";
import queryString from "query-string";


const PublicRoute = ({component: ComponentToRender,layout: Layout, ...other}) => {
    const hashParams = queryString.parse(window.location.hash);
    return (
        <Route {...other} render={(props)=>{
            return (<>
                    {Layout !== undefined && hashParams["frameless"] != "true" ?
                        <Layout>
                            <ComponentToRender {...props}/>
                        </Layout>
                        :
                        <ComponentToRender {...props}/>
                    }
            </>)
        }}/>
    )
}
export default PublicRoute;
