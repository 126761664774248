import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { notificationsReducer } from "./notifications/duck";
import { headerReducer } from "./header/duck";
import { reducer as oidcReducer } from "redux-oidc";
import registarLekoviReducer from "./domain/RegistarLekovi/duck/reducers";
import registarMedicinskiSredstvaReducer from "./domain/RegistarMedicinskiSredstva/duck/reducers";
import registarKlinickiIspituvanjaReducer from "./domain/RegistarKlinickiIspituvanja/duck/reducers";
import userInfoReducer from "./duck/reducers"

const rootReducer = history => {
  return combineReducers({
    oidc: oidcReducer,
    user: userInfoReducer,
    router: connectRouter(history),
    notifications: notificationsReducer,
    header: headerReducer,
    registarLekovi: registarLekoviReducer,
    registarMedicinskiSredstva: registarMedicinskiSredstvaReducer,
    registarKlinickiIspituvanja: registarKlinickiIspituvanjaReducer,
  });
};

export default rootReducer;
