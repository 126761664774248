import constants from "./constants";

const defaultState = {
  items: undefined, //undefined == loading, [] == empty
  loadingItems: false,
  processing: undefined,
};

export default function reducer(currentState = defaultState, action) {
  switch (action.type) {
    case constants.MEDICINSKI_SREDSTVA_RESET:
      return defaultState;

    case constants.MEDICINSKI_SREDSTVA_FETCH_REQUEST:
      return {
        ...currentState,
        items: undefined,
        loadingItems: true,
      };
    case constants.MEDICINSKI_SREDSTVA_FETCH_FAIL:
      return {
        ...currentState,
        loadingItems: false,
        items: defaultState.items,
      };

    case constants.MEDICINSKI_SREDSTVA_FETCH_SUCCESS:
      return {
        ...currentState,
        loadingItems: false,
        items: action.payload,
      };

   
    case constants.ITEMS_RESET_ARRAY:
      return {
        ...currentState,
        items: [],
      };

    default:
      return currentState;
  }
}
