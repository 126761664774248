import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import moment from "moment";
import {
  AssecoDataTable,
  LoadingComponent,
  NoItemsComponent,
  FDatePicker,
  FSelect,
  FTextField,
  FAutocomplete,
} from "asseco-commons";
import { properties } from "../../config/properties";
import { translate } from "../../util/lang/translate-wrapper";
// import PeriodicReportsAddEdit from "./PeriodicReportsAddEdit";
import AlertDialog from "../../util/alert/AlertDialog";
import { Field, Formik, Form } from "formik";
import {
  Grid,
  Container,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Tooltip,
  Button,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import SearchIcon from "@material-ui/icons/Search";
import { renderDate } from "../../util/helper/generalHelpers";
import i18next from "../../util/lang/i18n";
import { withNamespaces } from "react-i18next";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";

function KlinickiIspituvanjaComponent(props) {
  const dispatch = useDispatch();
  const [rerenderSifrarnik, setRerenderSifrarnik] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.outerWidth - 55 + "px");
  const [tableData, setTableData] = useState(props.items);

  useEffect(() => {
    return function cleanup() {
      rerender();
    };
  }, []);

  useEffect(() => {
    setTableData(undefined);
    setTimeout(() => {
      setTableData(props.items);
    }, 1);
  }, [props.lng, props.items]);

  useEffect(() => {
    props.fetchItems();
  }, []);

  const rerender = () => {
    setRerenderSifrarnik(!rerenderSifrarnik);
    props.resetReducer();
  };

  useEffect(() => {
    const handleTableResize = () =>
      setScreenWidth(window.outerWidth - 55 + "px");
    window.addEventListener("resize", handleTableResize);

    return () => window.removeEventListener("resize", handleTableResize);
  }, []);

  let initialValues = { datumDo: null, datumOd: null };

  const validationSchema = Yup.object().shape({});

  return (
    <>
      <div key={rerenderSifrarnik} style={{ marginBottom: "40px" }}>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            // isInitialValid={true}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              //   let val = {...values,
              //     dostavuvacType: values.dostavuvacType ? values.dostavuvacType.id : undefined,
              //     requestType: values.requestType ? values.requestType.id : undefined
              //   };
              //   props.fetchItems(val).then(() => {
              //     // actions.setSubmitting(false);
              //   });
            }}
            render={(formikProps) => (
              <Form autoComplete="off" style={{ padding: "20px" }}>
                <Card style={{ backgroundColor: "#fcfcfc" }}>
                  <CardHeader
                    title={
                      <Typography variant="subtitle1">
                        {translate("app.klinickiIspituvanja.header")}
                      </Typography>
                    }
                  />
                
                </Card>
              </Form>
            )}
          />
        </Grid>
      </div>
      {/* {(props.loadingItems || props.processingNotifications) && <><LoadingComponent /><br/></>} */}
      {/* {props.items != undefined && props.items.length !== 0 && (
        <> */}
        <AssecoDataTable
          type={"devExtreme"}
          wordWrapEnabled={true}
          data={tableData}
          columnId={"id"}
          columns={[
            {
              name: "naslov",
              label: translate("app.klinickiIspituvanja.naslov"),
              cellProps: { align: "left", width: "450px", fontSize: "25px" },
            },
            {
              name: "broj_na_protokol",
              label: translate("app.klinickiIspituvanja.brojNaProtokol"),
              cellProps: { align: "left", width:"150px" },
            },
            {
              name: "ime_lek",
              label: translate("app.klinickiIspituvanja.imeLekKirilica"),
              cellProps: { align: "left" },
            },
            {
              name: "jacina",
              label: translate("app.klinickiIspituvanja.jacina"),
              cellProps: { align: "left", width:"150px" },
            },
            {
              name: "komintent",
              label: translate("app.klinickiIspituvanja.komitent"),
              cellProps: { align: "left" },
            },
            {
              name: "ispituvackiCentar",
              label: translate("app.klinickiIspituvanja.ispituvackiCentar"),
            },
            // {
            //   name: "datumpocetok",
            //   label: translate("app.klinickiIspituvanja.datumPocetok"),
            //   cellProps: { align: "left" },
            // },
          ]}
          filtering
          sorting
          paging
          export
          columnResizing
          icons={{
            details: (
              <OpenInBrowserIcon
                style={{ color: "#912777", fontSize: "20px" }}
              />
            ),
          }}
          tooltips={{}}
          onDetails={(item) => {
            dispatch(
              push({
                pathname: "/klinicki_ispituvanja/" + item.ime_lek,
                state: { props: item },
              })
            );
          }}
          actionsColumn={{
            alignColumnInTable: "left",
            width: 56,
          }}
        />
      {/* <klinickiIspituvanjaAddEdit
        item={props.selectedItem}
        onClose={() => closeDialog()}
        onSave={(item) => {
          props.saveItem(item);
        }}
      /> */}
    </>
  );
}

export default withNamespaces()(KlinickiIspituvanjaComponent);
