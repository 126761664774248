import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector} from "react-redux";
import CancelIcon from "@material-ui/icons/Cancel";
import {translate} from "../../lang/translate-wrapper";
  
export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const fullname = useSelector((state) => state.oidc.user.profile.name);
  const username = useSelector((state) => state.oidc.user.profile.user_name);
  const email = useSelector((state) => state.oidc.user.profile.email);
  const komintent = useSelector((state) => state.user.userinfo.komintentnaziv);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Корисник</DialogTitle>
        <DialogContent>
          <DialogContentText>Детали за логираниот корисник</DialogContentText>
          <br />
          <TextField
            value={username}
            variant="outlined"
            margin="dense"
            label="Корисничко име"
            type="name"
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
          <TextField
            value={fullname}
            variant="outlined"
            margin="dense"
            InputProps={{
              readOnly: true,
            }}
            label="Име и презиме"
            type="name"
            fullWidth
          />
          <TextField
            value={email}
            variant="outlined"
            margin="dense"
            InputProps={{
              readOnly: true,
            }}
            label="Е-маил адреса"
            type="email"
            fullWidth
          />
          <TextField
              value={komintent}
              variant="outlined"
              margin="dense"
              InputProps={{
                readOnly: true,
              }}
              label="Коминтент"
              type="email"
              fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={props.onClose} variant="contained">
            <CancelIcon style={{marginRight: "5px"}}/>
            {translate("app.generic.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
