import constants from "./constants";

const defaultState = {
  items: undefined, //undefined == loading, [] == empty
  loadingItems: false,
  processing: undefined,
  processingNotifications: undefined,
};

export default function reducer(currentState = defaultState, action) {
  switch (action.type) {
    case constants.KLINICKI_ISPITUVANJA_RESET:
      return defaultState;

    case constants.KLINICKI_ISPITUVANJA_FETCH_REQUEST:
      return {
        ...currentState,
        items: undefined,
        loadingItems: true,
      };
    case constants.KLINICKI_ISPITUVANJA_FETCH_FAIL:
      return {
        ...currentState,
        loadingItems: false,
        items: defaultState.items,
      };

    case constants.KLINICKI_ISPITUVANJA_FETCH_SUCCESS:
      return {
        ...currentState,
        loadingItems: false,
        items: action.payload,
      };

    case constants.KLINICKI_ISPITUVANJA_CREATE_SUCCESS:
      return {
        ...currentState,
        items: [action.payload, ...currentState.items],
      };

    case constants.KLINICKI_ISPITUVANJA_UPDATE_SUCCESS:
      return {
        ...currentState,
        items: currentState.items.map((item) => {
          if (item.id === action.payload.id) return action.payload;
          else return item;
        }),
      };
    case constants.KLINICKI_ISPITUVANJA_DELETE_REQUEST:
      return {
        ...currentState,
        processing: true,
      };
    case constants.KLINICKI_ISPITUVANJA_DELETE_SUCCESS:
      return {
        ...currentState,
        items: currentState.items.filter((item) => {
          return item.id !== action.payload.id;
        }),
        processing: false,
      };
    case constants.KLINICKI_ISPITUVANJA_DELETE_FAIL:
      return {
        ...currentState,
        processing: false,
      };
    case constants.ITEMS_RESET_ARRAY:
      return {
        ...currentState,
        items: [],
      };

    default:
      return currentState;
  }
}
