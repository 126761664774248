import React, {Fragment} from 'react';
import { useSelector} from "react-redux";
import {isAuthenticated, isAuthorized, isLoadingUser} from "./auth-helper";
import * as PropTypes from "prop-types";
import _ from "lodash";

function HasAuth(props) {

    const debugConsole = false;

    const oidc = useSelector(state=>state.oidc);
    const user = useSelector(state=>state.oidc.user);
    const permissions = useSelector(state=>_.get(state,"authorization.permissions"))
    const roles = useSelector(state=>_.get(state,"user.userinfo.roles"))
    const customUserDetails = useSelector(state=>_.get(state,"authorization.customUserDetails"))

    const { any, anyRole, condition, onFailRender: OnFailRender, onFail} = props;



    if(isLoadingUser(oidc))
        return <div>loading user</div>;

    if(!isAuthenticated(user))
    {
        console.warn("user not authenticated")

        if(onFail)
        {
            const returnedComponent = onFail({oidc, userDetails: customUserDetails, userPermissions:permissions, userRoles: roles, reason:"unauthenticated"});
            if(returnedComponent)
                return returnedComponent;
        }

        return <Fragment/>
    }

    if(anyRole && anyRole.length>0)
    {
        if (roles === undefined){
            return <div>Loading</div>;
        }

        if(!isAuthorized(roles,anyRole))
        {
            debugConsole && console.warn("user lacks roles for component")

            if(onFail)
            {
                const returnedComponent = onFail({oidc, userDetails: customUserDetails, userPermissions:permissions, userRoles: roles, reason:"unauthorized"});
                if(returnedComponent)
                    return returnedComponent;
            }

            return <Fragment/>
        }
    }

    if(any && any.length>0)
    {
        if (permissions === undefined){
            return <div>Loading</div>;
        }

        if(!isAuthorized(permissions,any))
        {
            debugConsole && console.warn("user lacks permissions for component")

            if(onFail)
            {
                const returnedComponent = onFail({oidc, userDetails: customUserDetails, userPermissions:permissions, userRoles: roles, reason:"unauthorized"});
                if(returnedComponent)
                    return returnedComponent;
            }

            return <Fragment/>
        }
    }

    if(condition)
    {
        if(!condition({oidc, userDetails: customUserDetails, userPermissions:permissions, userRoles: roles}))
        {
            debugConsole && console.warn(`user failed condition for component `,`|||${condition.toString()}|||`)

            if(onFail)
            {
                const returnedComponent = onFail({oidc, userDetails: customUserDetails, userPermissions:permissions, userRoles: roles, reason: "condition-failed"});
                if(returnedComponent)
                    return returnedComponent;
            }

            return <Fragment/>
        }
    }




    if (typeof props.children === "function")
    {
        return (
            <>{props.children({oidc, userDetails: customUserDetails, userPermissions:permissions, userRoles: roles})}</>
    );
    }
    else
        return (
            <>{props.children}</>
);
}

HasAuth.propTypes = {
    any: PropTypes.array,
    anyRole: PropTypes.array,
    condition: PropTypes.func
};


export default HasAuth;
