export default {
    REGISTER_FETCH_REQUEST: "REGISTER_FETCH_REQUEST",
    REGISTER_FETCH_SUCCESS: "REGISTER_FETCH_SUCCESS",
    REGISTER_FETCH_FAIL: "REGISTER_FETCH_FAIL",
    REGISTER_CREATE_REQUEST: "REGISTER_CREATE_REQUEST",
    REGISTER_CREATE_SUCCESS: "REGISTER_CREATE_SUCCESS",
    REGISTER_CREATE_FAIL: "REGISTER_CREATE_FAIL",
    REGISTER_UPDATE_REQUEST: "REGISTER_UPDATE_REQUEST",
    REGISTER_UPDATE_SUCCESS: "REGISTER_UPDATE_SUCCESS",
    REGISTER_UPDATE_FAIL: "REGISTER_UPDATE_FAIL",
    REGISTER_DELETE_REQUEST: "REGISTER_DELETE_REQUEST",
    REGISTER_DELETE_SUCCESS: "REGISTER_DELETE_SUCCESS",
    REGISTER_DELETE_FAIL: "REGISTER_DELETE_FAIL",
    REGISTER_RESET: "REGISTER_RESET",

    ITEMS_RESET_ARRAY: "ITEMS_RESET_ARRAY:",
  };
  