import actions from "./actions";
import {getUser} from "../api/userInfoApi";

const getUserInfo = (formData) => {
    return (dispatch, getState) => {
        dispatch(actions.getUserInfoRequest());
        return getUser(formData)
            .then((resultData) => {
                dispatch(actions.getUserInfoSuccess(resultData));
                return Promise.resolve(resultData);
            })
            .catch((e) => {
                dispatch(actions.getUserInfoFail(e));
                return Promise.resolve(e);
            });
    };
};

export default {
    getUserInfo
}