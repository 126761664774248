import React, { useEffect } from "react";
import {useDispatch } from "react-redux";
import moment from "moment";
import operations from "./duck/operations";
import {
  AssecoDataTable, LoadingComponent, NoItemsComponent,
  FDatePicker, FSelect, FTextField, FAutocomplete,
} from "asseco-commons";
import { properties } from "../../config/properties";
import { translate } from "../../util/lang/translate-wrapper";
// import PeriodicReportsAddEdit from "./PeriodicReportsAddEdit";
import AlertDialog from "../../util/alert/AlertDialog";
import { Field, Formik, Form } from "formik";
import AddIcon from "@material-ui/icons/Add";
import LayersClearIcon from "@material-ui/icons/LayersClear";
import {
    Grid, Box, Tooltip, Button, Typography, Paper,
    Dialog, TextField, makeStyles, Table, TableBody,
    TableCell, TableHead, TableRow, Breadcrumbs, Link
} from "@material-ui/core";
import * as Yup from "yup";
import { push, goBack } from "connected-react-router";
import {renderDateTextField} from "../../util/helper/generalHelpers";
import { withNamespaces } from 'react-i18next';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box  p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      fontSize:"10px"
    },
    tab: {
        border: "2px solid #df4c4c",
        color: "#df4c4c",
    },
    textField: {
        width: '95%',      
        
    },
    inputProps: {
        paddingLeft: "0px !important",
        margin: "0px !important",
        height: "30px",
        fontSize:"14px",
        backgroundColor:"#fcfcfc",
    },
    inputPropsMultiline: {
        paddingLeft: "12px !important",
        paddingTop: "6px",
        fontSize:"14px",
        backgroundColor:"#fcfcfc",
    },
    head: {
        fontWeight: "bold"
    },

  }));

function LekoviDetails(props) {
  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(undefined);
  const [value, setValue]= React.useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const propsLekovi = props.location.state.props;
  const [proizvoditeli, setProizvoditeli] = React.useState(undefined);


  useEffect(() => {
      debugger;
    dispatch(operations.fetchDetails(propsLekovi.id)).then((res) => {
        debugger;
        setProizvoditeli(res);
    });
  }, []);


    useEffect(() => {
        if(props.closeDeleteDialog === true)
            setOpenDeleteAlert(false);
    }, [props.closeDeleteDialog]);

    function handleClick(event) {
      dispatch(goBack("/lekovi"))
      }

  function closeDialog() {
    props.setSelectedItem(undefined);
  }
  
  let initialValues = {datumDo:null, datumOd:null};

  const validationSchema = Yup.object().shape({
   });


  return (
    <>  
        <div className={classes.root}>
         <TabPanel value={value} index={0}>
         <Breadcrumbs style={{marginTop:"-20px",  marginBottom:"20px"}}>
            <Link style={{fontSize: "12px", cursor:"pointer" }} color="inherit" onClick={handleClick} >
                {translate("app.registarLekovi.registarLekovi")}
            </Link>
            <Typography style={{fontSize:"14px"}} color="textPrimary">{propsLekovi.ime_lek_latinica}</Typography>
        </Breadcrumbs>

            <Grid container spacing={1} xs={12} style={{fontSize:"12px"}}>
                <Grid item xs={2} style={{wordWrap: "break-word"}}>
                    {translate("app.registarLekovi.brojOsnovnoResenie")}/{translate("app.registarLekovi.malmedSifra")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        className={classes.textField}
                        fullWidth
                        value={propsLekovi.osnovno_broj_resenie}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.FZOMSifra")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField}
                        fullWidth 
                        value={propsLekovi.fzom_sifra}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.brojModificiranoResenie")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        // value={"adrenalin"}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.datumOsnovnoResenie")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={renderDateTextField(propsLekovi.osnovno_datum_resenie)}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.brojPrenos")}/{translate("app.registarLekovi.arhivskiBroj")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField}
                        fullWidth 
                        value={propsLekovi.broj_prenos}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.datumPrenos")}/{translate("app.registarLekovi.datumArhiva")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={renderDateTextField(propsLekovi.datum_prenos)}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.brojOdobrenieIzvestuvanje")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField}
                        fullWidth 
                        value={propsLekovi.broj_odobrenie_notifikacija}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.datumOdobrenieIzvestuvanje")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={renderDateTextField(propsLekovi.datum_odobrenie_notifikacija)}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.brojModificiranoResenie")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField}
                        fullWidth 
                        // value={"adrenalin"}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.datumModificiranoResenie")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        // value={"adrenalin"}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.datumVaznost")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField}
                        fullWidth 
                        value={renderDateTextField(propsLekovi.datum_vaznost)}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.brojUkinuvanje")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={propsLekovi.broj_ukinuvanje}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.datumUkinuvanje")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField}
                        fullWidth 
                        value={renderDateTextField(propsLekovi.datum_ukinuvanje)}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.vidLek")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={propsLekovi.tip_lek}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.tipBaranjeLek")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        // value={"adrenalin"}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.imeLekKirilica")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={propsLekovi.ime_lek_kirilica}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.podnositelBaranje")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={propsLekovi.komintent}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.imeLekLatinica")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={propsLekovi.ime_lek_latinica}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.genericnoIme")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={propsLekovi.inn}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.jacina")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={propsLekovi.jacina}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.farmacevtskaForma")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={propsLekovi.farmcevtska_forma}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.atcKod")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={propsLekovi.atc_kod}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid> <Grid item xs={2}>
                    {translate("app.registarLekovi.eanKod")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={propsLekovi.ean_kod}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.pozitivnaLista")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={propsLekovi.pozitivna_lista}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.kontaktPakuvanje")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={propsLekovi.kontakt_pakuvanje}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputProps,
                            readOnly: true
                        }}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.pakuvanje")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={propsLekovi.pakuvanje}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputPropsMultiline,
                            readOnly: true,
                        }}
                        multiline
                        rows={4}
                        />
                </Grid>
                <Grid item xs={2}>
                    {translate("app.registarLekovi.sostav")}:
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        value={propsLekovi.sostav}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            className: classes.inputPropsMultiline,
                            readOnly: true,
                        }}
                        multiline
                        rows={4}
                        />
                </Grid>
                <Grid
            item
            xs={12}
            style={{
              marginTop: "25px",
              paddingLeft: "2px",
              paddingTop: "2px",
              paddingRight: "2px",
              borderRadius: "7px",
              background:
                "linear-gradient(to bottom, #902777, rgba(0, 0, 0, 0) 45%)",
            }}
          >
            <AssecoDataTable
              type={"devExtreme"}
              wordWrapEnabled={true}
              data={proizvoditeli}
              columnId={"id"}
              columns={[
                {
                  name: "ime_proizvoditel",
                  label: translate("app.klinickiIspituvanja.proizvoditel"),
                  cellProps: { align: "left", },
                },
                {
                  name: "drzava_naziv",
                  label: translate("app.klinickiIspituvanja.drzava"),
                  cellProps: { align: "left", },
                },
                {
                  name: "grad_naziv",
                  label: translate("app.klinickiIspituvanja.grad"),
                  cellProps: { align: "left", },
                },
                {
                  name: "adresa_proizvoditel",
                  label: translate("app.klinickiIspituvanja.adresa"),
                  cellProps: { align: "left", },
                },
                {
                  name: "naziv_mesto",
                  label: translate("app.klinickiIspituvanja.nazivMesto"),
                  cellProps: { align: "left", },
                },
              ]}
              filtering
              sorting
              columnResizing
            />
          </Grid>
            </Grid>
            </TabPanel>
            </div>

      {props.items !== undefined && props.items.length === 0 && (
        <Grid
          style={{ marginTop: "10px", textAlign: "center" }}
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <Paper style={{ paddingBottom: 10 + "px", paddingTop: 10 + "px" }}>
              <LayersClearIcon />
              <Typography component="h5" variant="h6" color=" inherit" noWrap>
                {translate("app.registarLekovi.noResult")}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
     
   
    </>
  );
}

export default withNamespaces()(LekoviDetails);
