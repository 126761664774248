import constants from "./constants";

const getUserInfoRequest = (request) => {
    return {
        type: constants.USER_INFO_FETCH_REQUEST,
        payload: request,
    };
};

const getUserInfoSuccess = (user) => {
    return {
        type: constants.USER_INFO_FETCH_SUCCESS,
        payload: user,
    };
};

const getUserInfoFail = (error) => {
    return {
        type: constants.USER_INFO_FETCH_FAIL,
        payload: error,
    };
};
const languageValueChange = (lng) => {
    return {
        type: constants.LANGUAGE_VALUE_CHANGE,
        payload: lng,
    };
};

export default {
    getUserInfoRequest,
    getUserInfoSuccess,
    getUserInfoFail,
    languageValueChange,
}