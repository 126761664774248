import actions from "./actions";
import {
  getIspituvaciById,
  getKlinickiIspituvanja,
  getProizvoditeliById,
  searchKlinickiIspituvanja,
} from "../../../api/registarKlinickiIspituvanjaApi";

const fetchItems = (formData) => {
  return (dispatch, getState) => {
    dispatch(actions.fetchRequest());
    return getKlinickiIspituvanja()
      .then((resultData) => {
        dispatch(actions.fetchSuccess(resultData));
        return Promise.resolve(resultData);
      })
      .catch((e) => {
        dispatch(actions.fetchFail(e));
        Promise.reject(e);
      });
  };
};

const searchItems = (formData) => {
  return (dispatch, getState) => {
    dispatch(actions.fetchRequest());
    return searchKlinickiIspituvanja(formData)
      .then((resultData) => {
        dispatch(actions.fetchSuccess(resultData));
        return Promise.resolve(resultData);
      })
      .catch((e) => {
        dispatch(actions.fetchFail(e));
        Promise.reject(e);
      });
  };
};

const fetchProizvoditeliById = (id) => {
  return (dispatch, getState) => {
    dispatch(actions.fetchRequestProizvoditeli());
    return getProizvoditeliById(id)
      .then((resultData) => {
        dispatch(actions.fetchSuccessProizvoditeli(resultData));
        return Promise.resolve(resultData);
      })
      .catch((e) => {
        dispatch(actions.fetchFailProizvoditeli(e));
        Promise.reject(e);
      });
  };
};

const fetchIspituvaciById = (id) => {
  return (dispatch, getState) => {
    dispatch(actions.fetchRequestIspituvaci());
    return getIspituvaciById(id)
      .then((resultData) => {
        dispatch(actions.fetchSuccessIspituvaci(resultData));
        return Promise.resolve(resultData);
      })
      .catch((e) => {
        dispatch(actions.fetchFailIspituvaci(e));
        Promise.reject(e);
      });
  };
};

const resetReducer = () => {
  return (dispatch, getState) => {
    return dispatch(actions.resetReducer());
  };
};

export default {
  fetchItems,
  searchItems,
  resetReducer,
  fetchProizvoditeliById,
  fetchIspituvaciById
};
