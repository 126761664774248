import React, {Component} from 'react';
// import PropTypes from 'prop-types';

class NoMatchComponent extends Component {
    render() {
        return (
            <div>
                404
            </div>
        );
    }
}

// NoMatchComponent.propTypes = {};

export default NoMatchComponent;
