import React, { Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { translate } from "./util/lang/translate-wrapper";
import ProfilePage from "./util/auth/comps/ProfilePage";

function ProfileButton(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));

  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openProfilePage, setOpenProfilePage] = React.useState(false);
  const open = Boolean(anchorEl);

  function handleSignout(event) {
    props.dispatch(push("/logout"));
  }

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleOpenProfilePage = () => {
    setOpenProfilePage(true);
  };

  const handleCloseProfilePage = () => {
    setOpenProfilePage(false);
  };

  return (
    <>
      <Fragment>
        <IconButton
          aria-owns={open ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              marginTop: "52px"
            }
          }}
        >
          <MenuItem onClick={handleOpenProfilePage}>
            <PersonIcon style={{ marginRight: "10px" }} />
            Профил
          </MenuItem>
          <MenuItem onClick={handleSignout}>
            <ExitToAppIcon style={{ marginRight: "10px" }} />
            Одјава
          </MenuItem>
        </Menu>
      </Fragment>
      {openProfilePage && (
        <ProfilePage open={openProfilePage} onClose={handleCloseProfilePage} />
      )}
    </>
  );
}

export default connect()(ProfileButton);
