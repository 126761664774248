import React, { Component, Fragment } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography/Typography";
import {FormControl, Select, MenuItem, FormHelperText, InputLabel} from "@material-ui/core"
import ProfileButton from "./profile-button";
import { connect } from "react-redux";
import { Link } from "@material-ui/core";
import MalmedLogo from "./util/images/malmed-logo.jpg"
import { properties } from "./config/properties";
import { Link as RouterLink } from "react-router-dom";
import NotificationButton from "./notification-button";
import TabsBar from "./TabsBar";
import MacedonianFlag from "./util/images/MacedoniaFlag.jpg"
import AlbanianFlag from "./util/images/AlbaniaFlag.png"
import UKFlag from "./util/images/UKFlag.jpg"
import i18next from "./util/lang/i18n";
import actions from "./duck/actions";

const styles = createStyles((theme) => ({
  title: {
    flexGrow: 1,
    "& > * + *": {
      marginLeft: "4rem",
    },
    "& a:hover": {
      color: "#c3dafe",
      textDecoration: "none",
      cursor: "pointer",
    },
  },
  links: {
    "& a": {
      fontSize: 14,
      fontWeight: "bold",
      marginRight: "4rem",
    },
    "& a:hover": {
      color: "#c3dafe",
      textDecoration: "none",
      cursor: "pointer",
    },
  },
  grow: {
    flexGrow: 1,
  },
  dashboard: {
    textDecoration: "none",
  },
  imgPadding: {
    paddingTop: theme.spacing(1),
    paddingLeft:"5px"
  },
}));

class AppbarContents extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      languageValue: localStorage.getItem("appLanguage")? localStorage.getItem("appLanguage") : "mk"
    };
  }

  render() {
    const { classes } = this.props;
    const handleChange = (event) => {
      this.setState({languageValue: event.target.value})
      this.props.languageChange(event.target.value);
      localStorage.setItem("appLanguage",event.target.value);
      i18next.changeLanguage(event.target.value)
    };
    return (
      <Fragment>
        <RouterLink to={"/"} className={classes.imgPadding} >
          <img
            width="95"
            height="45"
            style={{ backgroundSize: "cover" }}
            src={MalmedLogo}
            style={{borderRadius:"4px",}}
          />
        </RouterLink>
        {/* <RouterLink to="/" key="/" style={{ textDecoration: "none" }}>
          <Typography
            component="a"
            //href="/"
            variant="h3"
            color="inherit"
            noWrap
            className={classes.dashboard}
            style={{
              color: "white",
              paddingLeft: "10px"
            }}
          >
           Malmed
          </Typography>
        </RouterLink> */}

        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {this.props.header.title}
        </Typography>
        <Typography className={classes.links}>
          {/* <Link color="default">DOMAINS</Link>
          <Link color="default">DASHBOARD</Link>
          <Link onClick={()=>{this.redirect()}} color="default">CLIENT</Link> */}
        </Typography>
        <Typography align="right">{this.props.fullName}</Typography>
        <div style={{ marginBottom:"-18px"}}>
          <TabsBar />
        </div>
        {/* <ProfileButton />
        <NotificationButton/> */}
        <FormControl style={{marginLeft:"10px", color:"white"}} className={classes.formControl}>
        <Select
          style={{color:"white",}}
          labelId="languange"
          id="demo-simple-select"
          value={this.state.languageValue}
          onChange={handleChange}
        >
          <MenuItem style={{}} value={"mk"}><img src={MacedonianFlag} width= "30px" height="15px" /></MenuItem>
          <MenuItem value={"al"}><img src={AlbanianFlag} width= "30px" height="15px" /></MenuItem>
          <MenuItem value={"en"}><img src={UKFlag} width= "30px" height="15px" /></MenuItem>
        </Select>
      </FormControl>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  header: state.header,
  // fullName: state.oidc.user.profile.name,
  state: state,
});
const mapDispatchToProps = (dispatch) => {
  return {
    languageChange: (lng) => dispatch(actions.languageValueChange(lng))
  }
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AppbarContents));
