import React, {Component} from "react";
import {connect} from "react-redux";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {notificationsConstants} from "./duck";
import "react-toastify/dist/ReactToastify.minimal.css";

class NotificationsContainer extends Component {

  componentDidUpdate(prevProps, prevState) {
    const { message } = this.props.notification;
    if (message && message.level)
      if (message.level === notificationsConstants.LEVEL_LOG) {
        toast(message.contents);
      } else {
        toast[message.level](message.contents);
      }
  }

  render() {
    return (
      <>
        <ToastContainer
          position="bottom-right"
          autoClose={6000}
          draggable
          pauseOnHover
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  notification: state.notifications,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsContainer);
