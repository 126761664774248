import constants from "./constants";

const fetchRequest = (request) => {
  return {
    type: constants.MEDICINSKI_SREDSTVA_FETCH_REQUEST,
    payload: request,
  };
};

const fetchSuccess = (items) => {
  return {
    type: constants.MEDICINSKI_SREDSTVA_FETCH_SUCCESS,
    payload: items,
  };
};

const fetchFail = (error) => {
  return {
    type: constants.MEDICINSKI_SREDSTVA_FETCH_FAIL,
    payload: error,
  };
};

const resetReducer = () => {
  return {
    type: constants.MEDICINSKI_SREDSTVA_RESET,
    payload: {},
  };
};
const resetItems = () => {
  return {
    type: constants.ITEMS_RESET_ARRAY,
    payload: {},
  };
};


export default {
  fetchRequest,
  fetchSuccess,
  fetchFail,
 
  resetReducer,
  resetItems,
};
