import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Box, Typography, Tabs, Tab, AppBar, makeStyles, withStyles } from "@material-ui/core";
import PropTypes from 'prop-types';
import  {translate} from "./util/lang/translate-wrapper"
import ListIcon from '@material-ui/icons/List';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faPills, faNotesMedical, faSyringe } from '@fortawesome/free-solid-svg-icons'
import {Link as RouterLink} from "react-router-dom";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box  p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
      border: "2px solid #df4c4c",
      color: "#df4c4c",
  }

}));


function Container(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if(window.location.pathname==="/registar-medicinski-sredstva")
        setValue(1);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 

  return (<>
    <div className={classes.root}>
      <AppBar position="static" style={{ marginBottom:"20px"}}>
        <Tabs style={{backgroundColor:"white", color:"#333333", }} 
              value={value} 
              onChange={handleChange}
              TabIndicatorProps={{style: {background:'#df4c4c', width:"30%" }}} >
                
          {/* <Tab style={{width:"30%", maxWidth:"100%"}} 
               label={<div style={{display:"inline-block"}}><FontAwesomeIcon style={{marginRight:"10px"}} icon={faPills} size="lg" /> {translate("app.register.registarLekovi")}</div>} {...a11yProps(0)} 
               classes={{
                   selected: classes.tab
               }}
               component={RouterLink} to={"/lekovi"}
              /> */}
          <Tab style={{width:"35%", maxWidth:"100%"}}
               label={<div style={{display:"inline-block"}}><FontAwesomeIcon style={{marginRight:"10px"}} icon={faSyringe} size="lg" /> {translate("app.register.registarMedicinskiSredstva")} </div> } {...a11yProps(1)} 
               classes={{
                selected: classes.tab
               }}
               component={RouterLink} to={"/registar-medicinski-sredstva"}/>
          <Tab style={{width:"35%", maxWidth:"100%"}}
               label={<div style={{display:"inline-block"}}><FontAwesomeIcon style={{marginRight:"10px"}} icon={faNotesMedical} size="lg" /> {translate("app.register.registarKlinickiIspituvanja")} </div> } {...a11yProps(2)} 
               classes={{
                selected: classes.tab
            }}
            component={RouterLink} to={"/klinicki-ispituvanja"}/>
        </Tabs>
      </AppBar>
     
    </div>

     
    </>
  );
}

export default Container;
