import actions from "./actions";

const clearAllNotifications = () => {
    return dispatch => {
        dispatch(actions.clearAllNotifications());
    }
}

export default {
    clearAllNotifications
};
