import Grid from "@material-ui/core/Grid";
import React from "react";

export const domainMessages_mk = {
  app: {
    inactivity: {
      title: "Неактивност",
      body:
        "Корисникот е автоматски одјавен од системот поради континуирана неактивност од 15 мин, т.е. временски интервал кој е дефиниран од страна на Агенција за Заштита на Лични Податоци. ",
      login: "Логирај се",
    },
    unauthorized: {
      title: "Неовластено",
      body: "Немате привилегии за пристап",
      goBack: "Врати се на почетна",
    },
    generic: {
      create: "Вметни",
      details: "Детали",
      edit: "Промени",
      delete: "Избриши",
      loading: "",
      cancel: "Откажи",
      save: "Зачувај",
      close: "Затвори",
      noItems: "Нема податоци.",
      addNew: "Додади ново",
      add: "Додади",
      search: "Пребарај",
      activate: "Активирај",
      deactivate: "Деактивирај",
      sync: "Синхронизирај",
      users: "Корисници",
      dateFrom: "Датум од",
      dateTo: "Датум до",
      copy:"Копирај",
      tooltip: {
        add: "Додади",
        edit: "Промени",
        delete: "Избриши",
        openReports: "Отвори зачувани или објавени извештаи",
        notify: "Испрати известување и креирај барања за достава на извештај на сите коминтенти",
        openBaranje: "Отвори го барањето"
      },
    },

    drawer: {
      managingUsersByGRoup: "Менаџирање на корисници по групи",
      showingUsersByGroup: "Преглед на корисници по група",
      dostavuvacTip: "Тип на доставувач",
      requestType: "Тип на барање",
      dostavaTip: "Тип на достава",
      baranjeDostavaIzvestaj: "Барање за достава на извештај",
      baranjeDostavaIzvestajClient: "Барање за достава на извештај - Коминтент"
    },
    
   
    form: {
        delete: "Брише",
        insert: "Додава",
        read: "Чита",
        update: "Ажурира",
        application: "Апликација",
        role: "Улога",
        user: "Корисничко име",
        usertab: "Корисниk",
        userGroup: "Група на корисник",
        auth: "Авторизација",
        admGroup: "Административни единици",
      },
     
      dodadi: "Додади нова улога",
      dodadiButton: "Додади",
      iscistiFiltri: "Исчисти ги сите внесени параметри во формата",
      iscistiFiltriButton: "Исчисти филтри",
      noResult: "Нема резултати за внесените параметри",
      search: "Пребарај",
      add: "Додади улога",
      edit: "Промени улога",
      swapToBoundaries: "Внеси датум-од и датум-до",
      swapToExact: "Внеси конкретен датум"
    ,
    register: {
      registarLekovi: "Регистар на лекови",
      registarMedicinskiSredstva: "Регистар на медицински средства",
      registarKlinickiIspituvanja: "Регистар на клинички испитувања",
    },
    registarLekovi: {
      registarLekovi: "Регистар на лекови",
      header: "Лекови",
      proizvoditel: "Производител",
      nositelOdobrenie: "Носител на одобрение",
      nacinIzdavanje: "Начин на издавање",
      sifra: "Шифра",
      resenie: "Решение",
      name: "Име",
      atc: "АТЦ",
      iscistiFiltri: "Исчисти филтри",
      search: "Пребарај",
      iscistiFiltriButton: "Исчисти",
      latinicnoIme: "Латинично име",
      genericnoIme: "Генерично име",
      jacina: "Јачина",
      pakuvanje: "Пакување",
      farmacevtskaForma: "Фармацевтска форма",
      nacinIzdavanje: "Начин издавање",
      proizvoditel: "Производител",
      nositelOdobrenie: "Носител на одобрение",
      brojResenie: "Број на решение",
      datumResenie: "Датум на решение",
      datumVaznost: "Датум на важност",
      datumObnova: "Датум на обнова",
      golemoprodaznaCenaBezzDDV: "Големопродажна цена без ДДВ",
      maloprodaznaCenaSoDDV: "Малопродажна цена со ДДВ",
      varijacii: "Варијации",
      brojOsnovnoResenie: "Број на основно решение",
      brojModificiranoResenie: "Број на модифицирано решение",
      malmedSifra: "Малмед шифра",
      brojPrenos: "Број на пренос",
      arhivskiBroj: "Архивски број",
      brojOdobrenieIzvestuvanje: "Број на одобрение/известување	",
      datumVaznost: "Датум важност",
      brojUkinuvanje: "Број на укинување",
      FZOMSifra: "ФЗОМ шифра",
      datumOsnovnoResenie: "Датум на основно решение",
      datumPrenos: "Датум на пренос",
      datumArhiva: "Датум архива",
      datumOdobrenieIzvestuvanje:"Датум на одобрение/известување",
      datumModificiranoResenie:"Датум на модифицирано решение",
      datumUkinuvanje: "Датум на укинување",
      vidLek: "Вид на лек",
      tipBaranjeLek: "Тип на барање на лек",
      podnositelBaranje: "Подносител на барање",
      imeLekKirilica: "Име на лек (кирилица)",
      imeLekLatinica: "Име на лек (латиница)",
      genericnoIme: "Генеричко име",
      jacina: "Јачина",
      farmacevtskaForma: "Фармацевтска Форма",
      kontaktPakuvanje: "Контакт пакување",
      pakuvanje: "Пакување",
      sostav: "Состав",
      tipMestoProizvodstvo: "Тип место производство",
      mestoProizvodstvo: "Место на производство",
      atcKod: "АТЦ код",
      eanKod: "EAN код",
      pozitivnaLista:"Позитивна листа",
      brailovoPismo: "Браилово писмо",
      nacinIzdavanje:"Начин на издавање",
      posebniPredupreduvanja: "Посебни предупредувања",
      doziranje: "Дозирање",
      datumPrenosDatumArhiva: "Датум на пренос/Датум архива",
      brojPrenosArhivskiBroj: "Број на пренос/Архивски број",
      drzava: "Држава",
      grad: "Град",
      nazivMesto: "Назив место",
      adresa: "Адреса",
      PRESCRIPTION: "ЛЕКОТ МОЖЕ ДА СЕ ИЗДАВА ВО АПТЕКА САМО НА РЕЦЕПТ (Р)",
      HEALTHORGANIZATION: "ЛЕКОТ МОЖЕ ДА СЕ УПОТРЕБУВА САМО ВО ЗДРАВСТВЕНА УСТАНОВА (З)",
      NOPRESCRIPTION: "ЛЕКОТ МОЖЕ ДА СЕ ИЗДАВА ВО АПТЕКА БЕЗ РЕЦЕПТ (БР)",
      ALL: "ЛЕКОТ МОЖЕ ДА СЕ ИЗДАВА И/ИЛИ ПРОДАВА, БЕЗ РЕЦЕПТ (БР*)",
    },
    medicinskiSredstva: {
      osnovniPodatoci: "Основни податоци",
      detali: "Детали",
      header:"Медицински средства",
      name: "Име",
      GMDNkod: "GMDN код",
      proizvoditel: "Производител",
      nositelOdobrenie: "Носител на одобрение",
      kategorija: "Категорија",
      kategorijaNamena: "Категорија според намена",
      iscistiFiltri: "Исчисти филтри",
      search: "Пребарај",
      iscistiFiltriButton: "Исчисти",
      naziv: "Назив",
      pakuvanje: "Пакување",
      nacinIzdavanje: "Начин на издавање",
      datumVaznost: "Датум на важност",
      datumResenie: "Датум на решение",
      brojResenie: "Број на решение",
      klasa: "Класа",
      mestoProizvodstvo: "Место на производство",
      grupaProizvodi: "Група на производи",
      medicinskiSredstva:"Регистар на медицински средства",
      nositel_odobrenie: "Носител на одобрение",
      nacin_izdavanje: "Начин на издавање",
      gmdn_kod: "GMDN код",
      osnovno_datum_resenie: "Датум на решение",
      osnovno_broj_resenie: "Број на решение",
      naziv_kategorija: "Категорија",
      naziv_klasa:"Класа",
      naziv_kategorija_namena: "Категорија според намена",
      naziv_grupa:"Група на медицински средства",     
      noResult: "Нема податоци.",
      nazivProdukt: "Назив на продукт",
      originalnoIme: "Оригинално име",
      kataloskiBroj: "Каталошки број",
    },
    klinickiIspituvanja: {
      header: "Клинички испитувања",
      klinickiIspituvanja: "Клинички испитувања",
      brojNaProtokol: "Број на протокол",
      jacina: "Јачиина",
      jacinaLek: "Јачина на лек",
      naslov: "Наслов",
      naslovIspituvanje: "Наслов на испитување",
      datumPocetok: "Датум на почеток",
      komitent: "Комитент",
      imeLekKirilica: "Име на лек (кирилица)",
      imeLekLatinica: "Име на лек (латиница)",
      ispituvac: "Испитувач",
      name: "Име",
      celNaIspituvanje: "Цел на испитување",
      sponzorIspituvanje: "Спонзор на испитување",
      fazaNaIspituvanjeto: "Фаза на испитувањето",
      farmacevtskaDoziranaForma: "Фармацевтска дозирана форма",
      glavenIspituvac: "Главен испитувач",
      drzava: "Држава",
      grad: "Град",
      adresa: "Адреса",
      nazivMesto: "Назив на место",
      proizvoditel: "Производител",
      naziv: "Назив",
      brojOdobrenie: "Број на одобрение",
      datumOdobrenie: "Датум на одобрение",
      ispituvackiCentar:"Испитувачки центар",
    },
    notifications: {
      dostavuvacTipFailFetch: "Проблем при пребарување на записи за тип на доставувач",
      dostavuvacTipCreateSuccess: "Успешно креирање на тип на доставувач",
      dostavuvacTipFailCreate: "Проблем при креирање на тип на доставувач",
      dostavuvacTipUpdateSuccess: "Успешна промена на тип на доставувач",
      dostavuvacTipFailUpdate: "Проблем при промена на тип на доставувач",
      dostavuvacTipDeleteSuccess: "Успешно бришење на тип на доставувач",
      dostavuvacTipFailDelete: "Проблем при бришење на тип на доставувач",
      periodicReportsFailFetch: "Проблем при пребарување на записи за тип на достава",
      periodicReportsCreateSuccess: "Успешно креирање на тип на достава",
      periodicReportsFailCreate: "Проблем при креирање на тип на достава",
      periodicReportsUpdateSuccess: "Успешна промена на тип на достава",
      periodicReportsFailUpdate: "Проблем при промена на тип на достава",
      periodicReportsDeleteSuccess: "Успешно бришење на тип на достава",
      periodicReportsFailDelete: "Проблем при бришење на тип на достава",
      requestTypeFailFetch: "Проблем при пребарување на записи за тип на барање",
      requestTypeCreateSuccess: "Успешно креирање на тип на барање",
      requestTypeFailCreate: "Проблем при креирање на тип на барање",
      requestTypeUpdateSuccess: "Успешна промена на тип на барање",
      requestTypeFailUpdate: "Проблем при промена на тип на барање",
      requestTypeDeleteSuccess: "Успешно бришење на тип на барање",
      requestTypeFailDelete: "Проблем при бришење на тип на барање",
      baranjeDostavaIzvestajFailFetch: "Проблем при пребарување на записи за барање за достава на извештај",
      baranjeDostavaIzvestajCreateSuccess: "Успешно креирање на барање за достава на извештај",
      baranjeDostavaIzvestajCreateFail: "Проблем при креирање на барање за достана на извештај",
      baranjeDostavaIzvestajUpdateSuccess: "Успешна промена на барање за достава на извештај",
      baranjeDostavaIzvestajUpdateFail: "Проблем при промена на барање за достава на извештај",
      baranjeDostavaIzvestajDeleteSuccess: "Успешно бришење на барање за достава на извештај",
      baranjeDostavaIzvestajDeleteFail: "Проблем при бришење на барање за достава на извештај",
      periodicReportsNotifySuccess: "Успешно испратени известувања до сите назначени коминтенти",
      periodicReportsNotifyFail: "Проблем при испраќање на известувања до сите назначени коминтенти",
      izvestajPotrosuvackaGetBaranjaFail: "Проблем при приказ на барања за достава на извештај за коминтент",
      userInfoFetchFail: "Проблем при пребарување на коминтент и улоги за корисникот односно клиентот кој се најавува",
      izvestajPotrosuvackaSaveFail: "Проблем при зачувување на извештаи за потрошувачка",
      izvestajPotrosuvackaSaveSuccess: "Успешно зачувани извештаи за потрошувачка",
      izvestajPotrosuvackaSubmitSuccess: "Успешно зачувани извештаи за потрошувачка и објавено барање",
      izvestajPotrosuvackaSubmitFail: "Проблем при зачувување на извештаи за потрошувачка и објавување на барање",
      invalidTableDataSave: "Проблем при зачувување на извештаи. Табелата содржи невалидни податоци",
      invalidTableDataSubmit: "Порблем при објавување на барање. Табелата содржи невалидни податоци",
      successCreate: "Успешно креирање на запис",
      failCreate: "Проблем при креирање на запис",
      successDelete: "Успешно избришан запис",
      failDelete: "Проблем при бришење на записот",
      successEdit: "Записот е успешно променет",
      failEdit: "Проблем при промена на записот",
      successSave: "Записот е успешно зачуван",
      failSave: "Записот е неуспешно зачуван",
      conflictingProperty:"Конфликтно поле",
      conflictingValue:"Конфликтна вредност",
      messageId:"ИД на порака",
      constraint:{
        duplicate:"Дупликатен запис",
      },
      "409": "Постои запис за задолжените полиња во зададенатa временска рамка",
      conflict:{
        dateRange: "Записот има датум кој е конфликтен со други записи",
      },
      syncUsers: {
        success: "Успешна синхронизација!",
        fail: "Грешка со серверот!",
      },
      deactivateUsers: {
        success: "Успешно деактивирање на корисникот!",
        fail: "Грешка со серверот!",
      },
      activateUsers: {
        success: "Успешно активирање на корисникот!",
        fail: "Грешка со серверот!",
      },
      group: {
        success: "Успешна операција!",
        fail: "Грешка со серверот!",
      },

      errorRequired: "Полето е задолижително",
      errorSign: "Внесот не е во валиден формат",
      deleteConfirm: "Дали сте сигурни дека сакате да го избришете овој запис?",
      deleteConfirmDostavaType: "Дали сте сигурни дека сакате да го избришете овој запис? Ова може да потрае зависно од тоа колку известувања треба да се поништат.",
      saveConfirm: "Дали сте сигурни дека сакате да го зачувате записот?",
      activateConfirm: "Дали сте сигурни дека саката де го активирате записот?",
      deactivateConfirm:
        "Дали сте сигурни дека сакате да го деактивирате записот?",
      errorLength: "Внесовте повеќе од дозволените 120 карактери",
      errorDate: "'Датум До' не може да биде порано од 'Датум Од'",
      ErrorDate: "Внесовте невалиден датум",
      trim: "Полето не може да започнува, завршува или содржи само празни карактери!",
      ErrorRequired: "Полето е задолжително",
      clearAll: "Исчисти ги сите нотификации",
      empty: "Нема нотификации",
      numbersOnly: "Полето може да содржи само броеви",
    },
  },
};
