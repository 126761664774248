import React from "react";
import {SESSION_TERMINATED, USER_EXPIRED} from "redux-oidc";
import constants from "./constants";
// import dostavuvacTipConstants from "./../../domain/dostavuvac/duck/constants";
import userInfoConstants from "./../../duck/constants";
import { translate } from "../../util/lang/translate-wrapper";
import CheckIcon from "@material-ui/icons/Check";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const defaultState = {
  notifications: [],
  message:{}
}

export default function reducer(currentState = defaultState, action) {
  
  const addNotification = (level, contents, dontTranslate) => {
    if (currentState.notifications.length === 10) {
        currentState.notifications.pop()
    }
    currentState.notifications.unshift({level, contents, dontTranslate})
    return currentState.notifications;
  }

  switch (action.type) {
    case SESSION_TERMINATED:
    case USER_EXPIRED:
      return defaultState;

    case constants.CLEAR_ALL_NOTIFICATIONS:
      return {
        notifications: []
      };

    case constants.NO_PERMISSIONS:
      return {
        ...currentState,
        notifications: addNotification(constants.LEVEL_ERROR, "app.notifications.no_permissios_to_access_resource"),
        message: {
          type: constants.TYPE_TOAST,
          level: constants.LEVEL_ERROR,
          contents: <div style={{display: "flex", flexDirection: "row"}}>
                      <div style={{width: "13%", justifyContent: "center", alignContent: "center"}}><ErrorOutlineIcon/></div>
                      <div style={{width: "87%", justifyContent: "center", alignContent: "center"}}>{translate("app.notifications.no_permissios_to_access_resource")}</div>
                    </div>
        }
      };

      // case userInfoConstants.USER_INFO_FETCH_FAIL:
      //     return {
      //         ...currentState,
      //         notifications: addNotification(constants.LEVEL_ERROR,
      //             action.payload.data.status === 404 && action.payload.data.message ? action.payload.data.message : "app.notifications.userInfoFetchFail",
      //             action.payload.data.status === 404 && action.payload.data.message ? true : false),
      //         message: {
      //             type: constants.TYPE_TOAST,
      //             level: constants.LEVEL_ERROR,
      //             contents:
      //                 action.payload.data.status === 404 ?
      //                     <div style={{display: "flex", flexDirection: "row"}}>
      //                         <div style={{width: "13%", justifyContent: "center", alignContent: "center"}}><ErrorOutlineIcon/></div>
      //                         <div style={{width: "87%", justifyContent: "center", alignContent: "center"}}>
      //                             {action.payload.data.message ? action.payload.data.message : translate("app.notifications.userInfoFetchFail")}
      //                         </div>
      //                     </div>
      //                     :
      //                     <div style={{display: "flex", flexDirection: "row"}}>
      //                         <div style={{width: "13%", justifyContent: "center", alignContent: "center"}}><ErrorOutlineIcon/></div>
      //                         <div style={{width: "87%", justifyContent: "center", alignContent: "center"}}>{translate("app.notifications.userInfoFetchFail")}</div>
      //                     </div>
      //         },
      //     };

 

      // case dostavuvacTipConstants.DOSTAVUVAC_TIP_FETCH_FAIL:
      //   return {
      //     ...currentState,
      //     notifications: addNotification(constants.LEVEL_ERROR, "app.notifications.dostavuvacTipFailFetch"),
      //     message: {
      //       type: constants.TYPE_TOAST,
      //       level: constants.LEVEL_ERROR,
      //       contents: <div style={{display: "flex", flexDirection: "row"}}>
      //                   <div style={{width: "13%", justifyContent: "center", alignContent: "center"}}><ErrorOutlineIcon/></div>
      //                   <div style={{width: "87%", justifyContent: "center", alignContent: "center"}}>{translate("app.notifications.dostavuvacTipFailFetch")}</div>
      //                 </div>
      //     },
      //   };

      default:
        return currentState;
  }
}
