import constants from "./constants";

const clearAllNotifications = () => {
    return {
        type: constants.CLEAR_ALL_NOTIFICATIONS,
        payload: {}
    };
};

const noPermissions = () => {
    return {
        type: constants.NO_PERMISSIONS,
        payload: {}
    };
};

export default {
    noPermissions,
    clearAllNotifications
};
