import actions from "./actions";
import {
  fetchItms, 
  fetch_Details,
  fetch_Produkti,
} from "../../../api/registarMedicinskiSredstvaApi";


const fetchItems = (formData) => {
  return (dispatch, getState) => {
    dispatch(actions.fetchRequest());
    return fetchItms(formData)
      .then((resultData) => {
        dispatch(actions.fetchSuccess(resultData));
        return Promise.resolve(resultData);
      })
      .catch((e) => {
        dispatch(actions.fetchFail(e));
        Promise.reject(e);
      });
  };
};
const fetchDetails = (formData) => {
  debugger;
  return (dispatch, getState) => {
    dispatch(actions.fetchRequest());
    return fetch_Details(formData)
      .then((resultData) => {
        dispatch(actions.fetchSuccess(resultData));
        return Promise.resolve(resultData);
      })
      .catch((e) => {
        dispatch(actions.fetchFail(e));
        Promise.reject(e);
      });
  };
};

const fetchMedProdukti = (formData) => {
  debugger;
  return (dispatch, getState) => {
    dispatch(actions.fetchRequest());
    return fetch_Produkti(formData)
      .then((resultData) => {
        dispatch(actions.fetchSuccess(resultData));
        return Promise.resolve(resultData);
      })
      .catch((e) => {
        dispatch(actions.fetchFail(e));
        Promise.reject(e);
      });
  };
};

const resetReducer = () => {
  return (dispatch, getState) => {
    return dispatch(actions.resetReducer());
  };
};

export default {
  fetchItems,
  resetReducer,
  fetchDetails,
  fetchMedProdukti
};
