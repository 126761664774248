import constants from "./constants";

const fetchRequest = (request) => {
  return {
    type: constants.KLINICKI_ISPITUVANJA_FETCH_REQUEST,
    payload: request,
  };
};

const fetchSuccess = (items) => {
  return {
    type: constants.KLINICKI_ISPITUVANJA_FETCH_SUCCESS,
    payload: items,
  };
};

const fetchFail = (error) => {
  return {
    type: constants.KLINICKI_ISPITUVANJA_FETCH_FAIL,
    payload: error,
  };
};
const createRequest = (request) => {
  return {
    type: constants.KLINICKI_ISPITUVANJA_CREATE_REQUEST,
    payload: request,
  };
};

const createSuccess = (items) => {
  return {
    type: constants.KLINICKI_ISPITUVANJA_CREATE_SUCCESS,
    payload: items,
  };
};

const createFail = (error) => {
  return {
    type: constants.KLINICKI_ISPITUVANJA_CREATE_FAIL,
    payload: error,
  };
};

const updateRequest = (request) => {
  return {
    type: constants.KLINICKI_ISPITUVANJA_UPDATE_REQUEST,
    payload: request,
  };
};

const updateSuccess = (items) => {
  return {
    type: constants.KLINICKI_ISPITUVANJA_UPDATE_SUCCESS,
    payload: items,
  };
};

const updateFail = (error) => {
  return {
    type: constants.KLINICKI_ISPITUVANJA_UPDATE_FAIL,
    payload: error,
  };
};

const deleteRequest = (request) => {
  return {
    type: constants.KLINICKI_ISPITUVANJA_DELETE_REQUEST,
    payload: request,
  };
};

const deleteSuccess = (items) => {
  return {
    type: constants.KLINICKI_ISPITUVANJA_DELETE_SUCCESS,
    payload: items,
  };
};

const deleteFail = (error) => {
  return {
    type: constants.KLINICKI_ISPITUVANJA_DELETE_FAIL,
    payload: error,
  };
};

const fetchRequestProizvoditeli = (request) => {
  return {
    type: constants.KI_PROIZVODITELI_FETCH_REQUEST,
    payload: request,
  };
};

const fetchSuccessProizvoditeli = (items) => {
  return {
    type: constants.KI_PROIZVODITELI_FETCH_SUCCESS,
    payload: items,
  };
};

const fetchFailProizvoditeli = (error) => {
  return {
    type: constants.KI_PROIZVODITELI_FETCH_FAIL,
    payload: error,
  };
};

const fetchRequestIspituvaci = (request) => {
  return {
    type: constants.KI_ISPITUVACI_FETCH_REQUEST,
    payload: request,
  };
};

const fetchSuccessIspituvaci = (items) => {
  return {
    type: constants.KI_ISPITUVACI_FETCH_SUCCESS,
    payload: items,
  };
};

const fetchFailIspituvaci = (error) => {
  return {
    type: constants.KI_ISPITUVACI_FETCH_FAIL,
    payload: error,
  };
};

const resetReducer = () => {
  return {
    type: constants.KLINICKI_ISPITUVANJA_RESET,
    payload: {},
  };
};
const resetItems = () => {
  return {
    type: constants.ITEMS_RESET_ARRAY,
    payload: {},
  };
};

export default {
  fetchRequest,
  fetchSuccess,
  fetchFail,
  createRequest,
  createSuccess,
  createFail,
  updateRequest,
  updateSuccess,
  updateFail,
  deleteRequest,
  deleteSuccess,
  deleteFail,
  resetReducer,
  resetItems,
  fetchRequestProizvoditeli,
  fetchSuccessProizvoditeli,
  fetchFailProizvoditeli,
  fetchRequestIspituvaci,
  fetchSuccessIspituvaci,
  fetchFailIspituvaci,
};
