import React, { useEffect, useState } from "react";
import {useDispatch } from "react-redux";
import operations from "./duck/operations";
import moment from "moment";
import {
  AssecoDataTable,
  LoadingComponent,
  NoItemsComponent,
  FDatePicker,
  FSelect,
  FTextField,
  FAutocomplete,
} from "asseco-commons";
import { translate } from "../../util/lang/translate-wrapper";
import LayersClearIcon from "@material-ui/icons/LayersClear";
import {
    Grid, Box, Tooltip, Button, Typography, Paper,
    Dialog, TextField, makeStyles, Breadcrumbs, Link,
    Tab, AppBar, Tabs, useTheme, Card
} from "@material-ui/core";
import * as Yup from "yup";
import { push, goBack } from "connected-react-router";
import {renderDateTextField} from "../../util/helper/generalHelpers";
import { withNamespaces } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box  p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function TabPanel1(props) {
    const { children, value, index, ...other } = props;
    return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper square elevation={3} style={{paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", margin:"3px"}}  >
          <Typography>{children}</Typography>
        </Paper>
      )}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      fontSize:"10px"
    },
    tab: {
        border: "2px solid #df4c4c",
        color: "#df4c4c",
    },
    textField: {
        width: '95%',      
        
    },
    inputProps: {
        paddingLeft: "0px !important",
        margin: "0px !important",
        height: "30px",
        fontSize:"14px",
        backgroundColor:"#fcfcfc",
    },
    inputPropsMultiline: {
        paddingLeft: "12px !important",
        paddingTop: "6px",
        fontSize:"14px",
        backgroundColor:"#fcfcfc",
    },
    head: {
        fontWeight: "bold"
    },
    tabsRoot: {
        height:"40px",
        maxHeight:"50px",
        minHeight:"25px"
    },
    tabRoot: {
        height:"40px",
        maxHeight:"50px",
        minHeight:"25px"
    }
  }));

function MedicinskiSredstvaDetails(props) {
  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(undefined);
  const [value, setValue]= React.useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [proizvoditeli, setProizvoditeli] = React.useState(undefined);
  const [medProdukti, setMedProdukti] = useState(undefined);
  const propsMedSredstva = props.location.state.props;
  const propsLekovi = props.location.state.props;
  //const tableData = React.useState(dispatch(operations.fetchDetails(propsMedSredstva.id))).then(t=>console.log(t));

    //tableData.then(t=>console.log(t))
    //console.log(tableData)

    useEffect(() => {
        dispatch(operations.fetchDetails(propsMedSredstva.id)).then((res) => {
            setProizvoditeli(res);
        });
        dispatch(operations.fetchMedProdukti(propsMedSredstva.id)).then((res) => {
            setMedProdukti(res);
          });
    
      }, []);

    useEffect(() => {
        if(props.closeDeleteDialog === true)
            setOpenDeleteAlert(false);
    }, [props.closeDeleteDialog]);

    function handleClick(event) {
        dispatch(goBack("/medicinski_sredstva"))
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
        
    function a11yProps(index) {
        return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
        };
        
    }
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  
  let initialValues = {datumDo:null, datumOd:null};

  const validationSchema = Yup.object().shape({
   });


  return (
    <>  
        <div className={classes.root}>
         <TabPanel value={0} index={0}>
         <Breadcrumbs style={{marginTop:"-18px",  marginBottom:"18px"}}>
            <Link style={{fontSize: "12px", cursor:"pointer" }} color="inherit" onClick={handleClick} >
                {translate("app.medicinskiSredstva.medicinskiSredstva")}
            </Link>
            <Typography style={{fontSize:"14px"}} color="textPrimary">{propsMedSredstva.ime_medicinsko_pomagalo}</Typography>
        </Breadcrumbs>
        <AppBar style={{width:"48%",marginTop:"-9px", marginLeft:"3px"}} position="static" color="default">
            <Tabs
                classes={{
                    root: classes.tabsRoot
                }}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
                >
            <Tab classes={{
                    root: classes.tabRoot
                }} label={translate("app.medicinskiSredstva.osnovniPodatoci")} {...a11yProps(0)} />
            <Tab classes={{
                    root: classes.tabRoot
                }}label={translate("app.medicinskiSredstva.detali")} {...a11yProps(1)} />
            </Tabs>
        </AppBar>
        <SwipeableViews        
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
        >
            <TabPanel1 style={{ marginTop:"5px" }} value={value} index={0} dir={theme.direction}>
            <Grid
            container
            spacing={1}
            xs={12}
            style={{
              marginTop: "20px",
              fontSize: "12px",
              border: "1px solid #902777",
              borderRadius: "15px",
              padding: "10px",
            }}
          >
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.medicinskiSredstva.naziv")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsLekovi.ime_medicinsko_pomagalo}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.medicinskiSredstva.nositel_odobrenie")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsLekovi.komintent}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
                
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.medicinskiSredstva.nacin_izdavanje")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsLekovi.nacin_izdavanje}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.medicinskiSredstva.naziv_klasa")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsLekovi.naziv_klasa}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.medicinskiSredstva.datumVaznost")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={renderDateTextField(propsLekovi.datum_vaznost)}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.medicinskiSredstva.osnovno_broj_resenie")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsLekovi.osnovno_broj_resenie}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.medicinskiSredstva.osnovno_datum_resenie")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={renderDateTextField(propsLekovi.osnovno_datum_resenie)}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.medicinskiSredstva.naziv_kategorija_namena")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsLekovi.naziv_kategorija_namena}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.medicinskiSredstva.naziv_kategorija")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsLekovi.naziv_kategorija}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
	<Grid item xs={2} className={classes.gridText}>
              {translate("app.medicinskiSredstva.naziv_grupa")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsLekovi.naziv_grupa}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: "25px",
              paddingLeft: "2px",
              paddingTop: "2px",
              paddingRight: "2px",
              borderRadius: "7px",
              background:
                "linear-gradient(to bottom, #902777, rgba(0, 0, 0, 0) 45%)",
            }}
          >
            <AssecoDataTable
              type={"devExtreme"}
              wordWrapEnabled={true}
              data={proizvoditeli}
              columnId={"id"}
              columns={[
                {
                  name: "ime_proizvoditel",
                  label: translate("app.klinickiIspituvanja.proizvoditel"),
                  cellProps: { align: "left", },
                },
                {
                  name: "drzava_naziv",
                  label: translate("app.klinickiIspituvanja.drzava"),
                  cellProps: { align: "left", },
                },
                {
                  name: "grad_naziv",
                  label: translate("app.klinickiIspituvanja.grad"),
                  cellProps: { align: "left", },
                },
                {
                  name: "adresa_proizvoditel",
                  label: translate("app.klinickiIspituvanja.adresa"),
                  cellProps: { align: "left", },
                },
                {
                  name: "naziv_mesto",
                  label: translate("app.klinickiIspituvanja.nazivMesto"),
                  cellProps: { align: "left", },
                },
              ]}
              filtering
              sorting
              columnResizing
            />
          </Grid>

            </TabPanel1>
            
            <TabPanel1 style={{ marginTop:"8px" }} value={value} index={1} dir={theme.direction}>
            <Grid container spacing={1} xs={12} style={{fontSize:"12px"}}>
            <Grid
            item
            xs={12}
            style={{
              marginTop: "25px",
              paddingLeft: "2px",
              paddingTop: "2px",
              paddingRight: "2px",
              borderRadius: "7px",
              background:
                "linear-gradient(to bottom, #902777, rgba(0, 0, 0, 0) 45%)",
            }}
          >
            <AssecoDataTable
              type={"devExtreme"}
              wordWrapEnabled={true}
              data={medProdukti}
              columnId={"id"}
              columns={[
                {
                  name: "naziv",
                  label: translate("app.medicinskiSredstva.nazivProdukt"),
                  cellProps: { align: "left", },
                },
                {
                  name: "originalno_ime",
                  label: translate("app.medicinskiSredstva.originalnoIme"),
                  cellProps: { align: "left", },
                },
                {
                  name: "kataloski_broj",
                  label: translate("app.medicinskiSredstva.kataloskiBroj"),
                  cellProps: { align: "left", },
                },
                {
                  name: "pakuvanje",
                  label: translate("app.medicinskiSredstva.pakuvanje"),
                  cellProps: { align: "left", },
                },
              ]}
              filtering
              sorting
              columnResizing
            />
          </Grid>

            </Grid>
            </TabPanel1>
        </SwipeableViews>
            
            </TabPanel>
            </div> 
   
    </>
  );
}

export default withNamespaces()(MedicinskiSredstvaDetails);
