import i18next from 'i18next';
import { reactI18nextModule } from "react-i18next";
import {domainMessages_en} from './domainMessages_en';
import {domainMessages_mk} from './domainMessages_mk';
import {domainMessages_al} from './domainMessages_al';

i18next
    .use(reactI18nextModule)
    .init({
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        lng: localStorage.getItem("appLanguage")? localStorage.getItem("appLanguage") : "mk",
        // Using simple hardcoded resources for simple example
        resources: {
            en: {
                translation: {
                    ...domainMessages_en
                },
            },
            mk: {
                translation: {
                    ...domainMessages_mk
                },
            },
            al: {
                translation: {
                    ...domainMessages_al
                }
            }
        },
    });

export default i18next
