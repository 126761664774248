import Grid from "@material-ui/core/Grid";
import React from "react";

export const domainMessages_al = {
  app: {
    inactivity: {
      title: "Неактивност",
      body:
        "Корисникот е автоматски одјавен од системот поради континуирана неактивност од 15 мин, т.е. временски интервал кој е дефиниран од страна на Агенција за Заштита на Лични Податоци. ",
      login: "Логирај се",
    },
    unauthorized: {
      title: "Неовластено",
      body: "Немате привилегии за пристап",
      goBack: "Врати се на почетна",
    },
    generic: {
      create: "Вметни",
      details: "Детали",
      edit: "Промени",
      delete: "Избриши",
      loading: "",
      cancel: "Откажи",
      save: "Зачувај",
      close: "Затвори",
      noItems: "Нема податоци во листата.",
      addNew: "Додади ново",
      add: "Додади",
      search: "Пребарај",
      activate: "Активирај",
      deactivate: "Деактивирај",
      sync: "Синхронизирај",
      users: "Корисници",
      dateFrom: "Датум од",
      dateTo: "Датум до",
      copy:"Копирај",
      tooltip: {
        add: "Додади",
        edit: "Промени",
        delete: "Избриши",
        openReports: "Отвори зачувани или објавени извештаи",
        notify: "Испрати известување и креирај барања за достава на извештај на сите коминтенти",
        openBaranje: "Отвори го барањето"
      },
    },

    drawer: {
      managingUsersByGRoup: "Менаџирање на корисници по групи",
      showingUsersByGroup: "Преглед на корисници по група",
      dostavuvacTip: "Тип на доставувач",
      requestType: "Тип на барање",
      dostavaTip: "Тип на достава",
      baranjeDostavaIzvestaj: "Барање за достава на извештај",
      baranjeDostavaIzvestajClient: "Барање за достава на извештај - Коминтент"
    },
    
   
    form: {
        delete: "Брише",
        insert: "Додава",
        read: "Чита",
        update: "Ажурира",
        application: "Апликација",
        role: "Улога",
        user: "Корисничко име",
        usertab: "Корисниk",
        userGroup: "Група на корисник",
        auth: "Авторизација",
        admGroup: "Административни единици",
      },
     
      dodadi: "Додади нова улога",
      dodadiButton: "Додади",
      iscistiFiltri: "Исчисти ги сите внесени параметри во формата",
      iscistiFiltriButton: "Исчисти филтри",
      noResult: "Нема резултати за внесените параметри",
      search: "Пребарај",
      add: "Додади улога",
      edit: "Промени улога",
      swapToBoundaries: "Внеси датум-од и датум-до",
      swapToExact: "Внеси конкретен датум"
    ,
    register: {
      registarLekovi: "Regjistri i barnave",
      registarMedicinskiSredstva: "Regjistri i mjeteve mjekësore",
      registarKlinickiIspituvanja: "Regjistri i ekzaminimeve klinike ",
    },
    registarLekovi: {
      registarLekovi: "Regjistri i barnave",
      header: "Formular për kërkim të barnave",
      proizvoditel: "Prodhues",
      nositelOdobrenie: "Bartësi i lejes",
      nacinIzdavanje: "Mënnyra e lëshimit",
      sifra: "Shifra",
      resenie: "Aktvendim",
      name: "Emri",
      atc: "ATC",
      iscistiFiltri: "Fshij filtrat",
      search: "Kërko",
      iscistiFiltriButton: "Fshij",
      latinicnoIme: "Emri latinisht",
      genericnoIme: "Emri gjenerik",
      jacina: "Forca",
      pakuvanje: "Paketimi",
      farmacevtskaForma: "Forma farmaceutike",
      nacinIzdavanje: "Начин издавање",
      proizvoditel: "Prodhues",
      nositelOdobrenie: "Bartës i lejes",
      brojResenie: "Numri i aktvendimit",
      datumResenie: "Data e aktvendimit",
      datumVaznost: "Датум на важност",
      datumObnova: "Data e ripërtrirjes",
      golemoprodaznaCenaBezzDDV: "Големопродажна цена без ДДВ",
      maloprodaznaCenaSoDDV: "Малопродажна цена со ДДВ",
      varijacii: "Variacione",
      brojOsnovnoResenie: "Numri i aktvendimit fillestar",
      brojModificiranoResenie: "Numri i aktvendimit të modifikuar",
      malmedSifra: "Shifra Malmed",
      brojPrenos: "Број на пренос",
      arhivskiBroj: "Numri i arkivës",
      brojOdobrenieIzvestuvanje: "Numri i lejës/njoftimit",
      datumVaznost: "Data e vlefshmërisë",
      brojUkinuvanje: "Број на укинување",
      FZOMSifra: "Shifra FSSHM",
      datumOsnovnoResenie: "Data e aktvendimit fillestar",
      datumPrenos: "Data e transferimit",
      datumArhiva: "Data e arkivës",
      datumOdobrenieIzvestuvanje:"Data e lejës/njoftimit",
      datumModificiranoResenie:"Data e aktvendimit të modifikuar",
      datumUkinuvanje: "Data e ndalimit",
      vidLek: "Lloji i barit",
      tipBaranjeLek: "Lloji i kërkesës së barit",
      podnositelBaranje: "Parashtruesi i kërkesës",
      imeLekKirilica: "Emri i barit (qirilik)",
      imeLekLatinica: "Emri i barit (latinisht)",
      genericnoIme: "Emri gjenerik",
      jacina: "Forca",
      farmacevtskaForma: "Forma farmaceutike",
      kontaktPakuvanje: "Paketim kontaktues",
      pakuvanje: "Paketimi",
      sostav: "Përmbajtja",
      tipMestoProizvodstvo: "Lloj i vendit të prodhimit",
      mestoProizvodstvo: "Vendi i prodhimit",
      atcKod: "Kodi ATC",
      eanKod: "Kodi EAN",
      pozitivnaLista:"Lista pozitive",
      brailovoPismo: "Shenja Brajllovi",
      nacinIzdavanje:"Mënyra e lëshimit",
      posebniPredupreduvanja: "Paralajmërime të veçanta",
      doziranje: "Dozimi",
      datumPrenosDatumArhiva: "Датум на пренос/Data e arkivës",
      brojPrenosArhivskiBroj: "Број на пренос/Numri i arkivës",
      drzava: "Shteti",
      grad: "Qyteti",
      nazivMesto: "Vendi",
      adresa: "Adresa",
      PRESCRIPTION: "BARI MUND TË MERRET NË BARNATORE VETËM ME RECETË (R) ",
      HEALTHORGANIZATION: "BARI MUND TË PËRDORET VETËM NË INSTITUCION SHËNDETËSOR (SH)",
      NOPRESCRIPTION: "BARI MUND TË MERRET NË BARNATORE PA RECETË (PR)",
      ALL: "BARI MUND TË MERRET OSE/DHE SHITET, PA RECETË (PR*)",
    },
    klinickiIspituvanja: {
      header: "Hulumtimet klinike",
      klinickiIspituvanja: "Hulumtimet klinike",
      brojNaProtokol: "Numri i Protokolit",
      jacina: "Doza",
      jacinaLek: "Doza e barit",
      naslov: "Titulli",
      naslovIspituvanje: "Titulli i hulumtimit",
      datumPocetok: "Data e fillimit",
      komitent: "Klient",
      imeLekKirilica: "Emri i barit (cirilik)",
      imeLekLatinica: "Emri i barit (latinisht)",
      ispituvac: "Hulumtues",
      name: "Emri",
      celNaIspituvanje: "Qëllimi i hulumtimit",
      sponzorIspituvanje: "Sponsor i hulumtimit",
      fazaNaIspituvanjeto: "Faza e hulumtimit",
      farmacevtskaDoziranaForma: "Forma e dozimit farmaceutik",
      glavenIspituvac: "Hulumtuesi kryesor",
      drzava: "Shteti",
      grad: "Qyteti",
      adresa: "Adresa",
      nazivMesto: "Emri i vendit",
      proizvoditel: "Prodhuesi",
      naziv: "Emri",
      brojOdobrenie: "Numri i lejës",
      datumOdobrenie: "Data e lejës",
    },
    medicinskiSredstva: {
      osnovniPodatoci: "Informacione bazë",
      detali: "Detajet",
      header:"Formular për kërkim të mjeteve mjekësore",
      name: "Emri",
      GMDNkod: "Kodi GMDN",
      proizvoditel: "Prodhues",
      nositelOdobrenie: "Bartës i lejës",
      kategorija: "Kategoria",
      kategorijaNamena: "Kategoria sipas qëllimit",
      iscistiFiltri: "Fshij filtrat",
      search: "Kërko",
      iscistiFiltriButton: "Fshij",
      naziv: "Emri",
      pakuvanje: "Paketimi",
      nacinIzdavanje: "Mënyra e lëshimit",
      datumVaznost: "Data e vlefshmërisë",
      datumResenie: "Data e aktvendimit",
      brojResenie: "Numri i aktvendimit",
      klasa: "Klasa",
      mestoProizvodstvo: "Vendi i prodhimit",
      grupaProizvodi: "Grupa e prodhimit",
      medicinskiSredstva:"Regjistri i mjeteve mjekësore",
      nositel_odobrenie: "Bartësi i lejës",
      nacin_izdavanje: "Mënyra e lëshimit",
      gmdn_kod: "Kodi GMDN",
      osnovno_datum_resenie: "Data e aktvendimit",
      osnovno_broj_resenie: "Numri i aktvendimit",
      naziv_kategorija: "Kategoria",
      naziv_klasa:"Klasa",
      naziv_kategorija_namena: "Kategoria sipas qëllimit",
      naziv_grupa:"Grupa e prodhimit",
      nazivProdukt: "Emri i produktit",
      originalnoIme: "Emri origjinal",
      kataloskiBroj: "Numri i katalogut",
    },
    notifications: {
      dostavuvacTipFailFetch: "Проблем при пребарување на записи за тип на доставувач",
      dostavuvacTipCreateSuccess: "Успешно креирање на тип на доставувач",
      dostavuvacTipFailCreate: "Проблем при креирање на тип на доставувач",
      dostavuvacTipUpdateSuccess: "Успешна промена на тип на доставувач",
      dostavuvacTipFailUpdate: "Проблем при промена на тип на доставувач",
      dostavuvacTipDeleteSuccess: "Успешно бришење на тип на доставувач",
      dostavuvacTipFailDelete: "Проблем при бришење на тип на доставувач",
      periodicReportsFailFetch: "Проблем при пребарување на записи за тип на достава",
      periodicReportsCreateSuccess: "Успешно креирање на тип на достава",
      periodicReportsFailCreate: "Проблем при креирање на тип на достава",
      periodicReportsUpdateSuccess: "Успешна промена на тип на достава",
      periodicReportsFailUpdate: "Проблем при промена на тип на достава",
      periodicReportsDeleteSuccess: "Успешно бришење на тип на достава",
      periodicReportsFailDelete: "Проблем при бришење на тип на достава",
      requestTypeFailFetch: "Проблем при пребарување на записи за тип на барање",
      requestTypeCreateSuccess: "Успешно креирање на тип на барање",
      requestTypeFailCreate: "Проблем при креирање на тип на барање",
      requestTypeUpdateSuccess: "Успешна промена на тип на барање",
      requestTypeFailUpdate: "Проблем при промена на тип на барање",
      requestTypeDeleteSuccess: "Успешно бришење на тип на барање",
      requestTypeFailDelete: "Проблем при бришење на тип на барање",
      baranjeDostavaIzvestajFailFetch: "Проблем при пребарување на записи за барање за достава на извештај",
      baranjeDostavaIzvestajCreateSuccess: "Успешно креирање на барање за достава на извештај",
      baranjeDostavaIzvestajCreateFail: "Проблем при креирање на барање за достана на извештај",
      baranjeDostavaIzvestajUpdateSuccess: "Успешна промена на барање за достава на извештај",
      baranjeDostavaIzvestajUpdateFail: "Проблем при промена на барање за достава на извештај",
      baranjeDostavaIzvestajDeleteSuccess: "Успешно бришење на барање за достава на извештај",
      baranjeDostavaIzvestajDeleteFail: "Проблем при бришење на барање за достава на извештај",
      periodicReportsNotifySuccess: "Успешно испратени известувања до сите назначени коминтенти",
      periodicReportsNotifyFail: "Проблем при испраќање на известувања до сите назначени коминтенти",
      izvestajPotrosuvackaGetBaranjaFail: "Проблем при приказ на барања за достава на извештај за коминтент",
      userInfoFetchFail: "Проблем при пребарување на коминтент и улоги за корисникот односно клиентот кој се најавува",
      izvestajPotrosuvackaSaveFail: "Проблем при зачувување на извештаи за потрошувачка",
      izvestajPotrosuvackaSaveSuccess: "Успешно зачувани извештаи за потрошувачка",
      izvestajPotrosuvackaSubmitSuccess: "Успешно зачувани извештаи за потрошувачка и објавено барање",
      izvestajPotrosuvackaSubmitFail: "Проблем при зачувување на извештаи за потрошувачка и објавување на барање",
      invalidTableDataSave: "Проблем при зачувување на извештаи. Табелата содржи невалидни податоци",
      invalidTableDataSubmit: "Порблем при објавување на барање. Табелата содржи невалидни податоци",
      successCreate: "Успешно креирање на запис",
      failCreate: "Проблем при креирање на запис",
      successDelete: "Успешно избришан запис",
      failDelete: "Проблем при бришење на записот",
      successEdit: "Записот е успешно променет",
      failEdit: "Проблем при промена на записот",
      successSave: "Записот е успешно зачуван",
      failSave: "Записот е неуспешно зачуван",
      conflictingProperty:"Конфликтно поле",
      conflictingValue:"Конфликтна вредност",
      messageId:"ИД на порака",
      constraint:{
        duplicate:"Hyrja e dyfishtë",
      },
      "409": "Постои запис за задолжените полиња во зададенатa временска рамка",
      conflict:{
        dateRange: "Записот има датум кој е конфликтен со други записи",
      },
      syncUsers: {
        success: "Успешна синхронизација!",
        fail: "Грешка со серверот!",
      },
      deactivateUsers: {
        success: "Успешно деактивирање на корисникот!",
        fail: "Грешка со серверот!",
      },
      activateUsers: {
        success: "Успешно активирање на корисникот!",
        fail: "Грешка со серверот!",
      },
      group: {
        success: "Успешна операција!",
        fail: "Грешка со серверот!",
      },

      errorRequired: "Полето е задолижително",
      errorSign: "Внесот не е во валиден формат",
      deleteConfirm: "Дали сте сигурни дека сакате да го избришете овој запис?",
      deleteConfirmDostavaType: "Дали сте сигурни дека сакате да го избришете овој запис? Ова може да потрае зависно од тоа колку известувања треба да се поништат.",
      saveConfirm: "Дали сте сигурни дека сакате да го зачувате записот?",
      activateConfirm: "Дали сте сигурни дека саката де го активирате записот?",
      deactivateConfirm:
        "Дали сте сигурни дека сакате да го деактивирате записот?",
      errorLength: "Внесовте повеќе од дозволените 120 карактери",
      errorDate: "'Датум До' не може да биде порано од 'Датум Од'",
      ErrorDate: "Внесовте невалиден датум",
      trim: "Полето не може да започнува, завршува или содржи само празни карактери!",
      ErrorRequired: "Полето е задолжително",
      clearAll: "Исчисти ги сите нотификации",
      empty: "Нема нотификации",
      numbersOnly: "Полето може да содржи само броеви",
    },
  },
};
