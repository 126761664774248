import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LekoviComponent from "./LekoviComponent";
import operations from "./duck/operations";
import {Box, Typography, Tabs, Tab, AppBar, makeStyles, withStyles } from "@material-ui/core";
import PropTypes from 'prop-types';
import  {translate} from "../../util/lang/translate-wrapper";
import ListIcon from '@material-ui/icons/List';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faPills, faNotesMedical, faSyringe } from '@fortawesome/free-solid-svg-icons'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box  p={3}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
      border: "2px solid #df4c4c",
      color: "#df4c4c",
  }

}));
function LekoviContainer(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [selectedItem, setSelectedItem] = useState(undefined); //undefined == none, {} == new
  const [closeDeleteDialog, setCloseDeleteDialog] = useState(false);
  const { items, loadingItems, processing, processingNotifications} = useSelector((state) => state.registarLekovi);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function saveItem(item) {
    if(item.id)
      dispatch(operations.updateItem(item)).then(()=>setSelectedItem(undefined));
    else
      dispatch(operations.createItem(item)).then(()=>setSelectedItem(undefined));
  }

  function deleteItem(item) {
    dispatch(operations.deleteItem(item)).then(()=>setCloseDeleteDialog(true));
  }

  function fetchItems(item) {
    dispatch(operations.fetchItems(item));
  }

  function notifyAllKomintenti(item) {
    dispatch(operations.notifyKomintenti(item));
  }

  function reset() {
    dispatch(operations.resetReducer());
  }
  

  return (<>
    <div className={classes.root}>
        <LekoviComponent
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            items={items}
            saveItem={saveItem}
            deleteItem={deleteItem}
            fetchItems={fetchItems}
            resetReducer={reset}
            // loadingItems={loadingItems}
            notifyAllKomintenti={notifyAllKomintenti}
            closeDeleteDialog={closeDeleteDialog}
            // processing={processing}
            // processingNotifications={processingNotifications}
            /> 

    </div>

     
    </>
  );
}

export default LekoviContainer;
