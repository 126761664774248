import { httprequest } from "asseco-commons";
import { properties } from "../config/properties";
import {createUrlWithPathParams} from "../util/helper/url-paths-params";

export const fetchItms = (formData) => {
    return httprequest({
      url: `${properties.api.root}/lekovi`,
      method: "get",
    }).then((response) => Promise.resolve(response));
  };

  export const getLekovi = () => {
    return httprequest({
      url: `${properties.api.root}/lekovi`,
      method: "get"
    }).then((response) => Promise.resolve(response));
  };

  export const fetch_Details = (id) => {
    return httprequest({
      url: `${properties.api.root}/lekovi/findProizvoditeliByIdLek/`,
      params: {id: id},
      method: "get"
    }).then((response) => Promise.resolve(response));
  };