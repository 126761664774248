import Grid from "@material-ui/core/Grid";
import React from "react";

export const domainMessages_en = {
  app: {
    inactivity: {
      title: "Неактивност",
      body:
        "Корисникот е автоматски одјавен од системот поради континуирана неактивност од 15 мин, т.е. временски интервал кој е дефиниран од страна на Агенција за Заштита на Лични Податоци. ",
      login: "Логирај се",
    },
    unauthorized: {
      title: "Неовластено",
      body: "Немате привилегии за пристап",
      goBack: "Врати се на почетна",
    },
    generic: {
      create: "Вметни",
      details: "Details",
      edit: "Промени",
      delete: "Избриши",
      loading: "",
      cancel: "Откажи",
      save: "Зачувај",
      close: "Затвори",
      noItems: "Нема податоци во листата.",
      addNew: "Додади ново",
      add: "Додади",
      search: "Пребарај",
      activate: "Активирај",
      deactivate: "Деактивирај",
      sync: "Синхронизирај",
      users: "Корисници",
      dateFrom: "Датум од",
      dateTo: "Датум до",
      copy:"Копирај",
      tooltip: {
        add: "Додади",
        edit: "Промени",
        delete: "Избриши",
        openReports: "Отвори зачувани или објавени извештаи",
        notify: "Испрати известување и креирај барања за достава на извештај на сите коминтенти",
        openBaranje: "Отвори го барањето"
      },
    },

    drawer: {
      managingUsersByGRoup: "Менаџирање на корисници по групи",
      showingUsersByGroup: "Преглед на корисници по група",
      dostavuvacTip: "Тип на доставувач",
      requestType: "Тип на барање",
      dostavaTip: "Тип на достава",
      baranjeDostavaIzvestaj: "Барање за достава на извештај",
      baranjeDostavaIzvestajClient: "Барање за достава на извештај - Коминтент"
    },
    
   
    form: {
        delete: "Брише",
        insert: "Додава",
        read: "Чита",
        update: "Ажурира",
        application: "Апликација",
        role: "Улога",
        user: "Корисничко име",
        usertab: "Корисниk",
        userGroup: "Група на корисник",
        auth: "Авторизација",
        admGroup: "Административни единици",
      },
     
      dodadi: "Додади нова улога",
      dodadiButton: "Додади",
      iscistiFiltri: "Исчисти ги сите внесени параметри во формата",
      iscistiFiltriButton: "Исчисти филтри",
      noResult: "Нема резултати за внесените параметри",
      search: "Пребарај",
      add: "Додади улога",
      edit: "Промени улога",
      swapToBoundaries: "Внеси датум-од и датум-до",
      swapToExact: "Внеси конкретен датум"
    ,
    register: {
      registarLekovi: "Drug Register",
      registarMedicinskiSredstva: "Register of medical devices",
      registarKlinickiIspituvanja: "Register of clinical trials",
    },
    registarLekovi: {
      registarLekovi: "Medicine register",
      header: "Form for searchig medicines ",
      proizvoditel: "Producer",
      nositelOdobrenie: "Customer",
      nacinIzdavanje: "Manner of issuance",
      sifra: "Code",
      resenie: "Decision",
      name: "Name",
      atc: "ATC",
      iscistiFiltri: "Исчисти филтри",
      search: "Search",
      iscistiFiltriButton: "Исчисти",
      latinicnoIme: "Latin name",
      genericnoIme: "Generic name",
      jacina: "Strength",
      pakuvanje: "Package",
      farmacevtskaForma: "Pharmaceutical dosage form",
      nacinIzdavanje: "Manner of issuance",
      proizvoditel: "Manufacturer",
      nositelOdobrenie: "Customer",
      brojResenie: "Number of Decision",
      datumResenie: "Date of Decision",
      datumVaznost: "Date of validity",
      datumObnova: "Date of renewal",
      golemoprodaznaCenaBezzDDV: "Големопродажна цена без ДДВ",
      maloprodaznaCenaSoDDV: "Малопродажна цена со ДДВ",
      varijacii: "Варијации",
      brojOsnovnoResenie: "Number of initial decision",
      brojModificiranoResenie: "Number of modified decision",
      malmedSifra: "MALMED code",
      brojPrenos: "Number of transfer",
      arhivskiBroj: "Archive number",
      brojOdobrenieIzvestuvanje: "Approval / notification number	",
      datumVaznost: "Date of validity",
      brojUkinuvanje: "Cancellation number",
      FZOMSifra: "FZOM code",
      datumOsnovnoResenie: "Date of initial decision",
      datumPrenos: "Date of transfer",
      datumArhiva: "Archive date",
      datumOdobrenieIzvestuvanje:"Approval / notification date",
      datumModificiranoResenie:"Date of modified decision",
      datumUkinuvanje: "Cancellation date",
      vidLek: "Type of medicine",
      tipBaranjeLek: "Type of drug request",
      podnositelBaranje: "Applicant",
      imeLekKirilica: "Name of medicine (Cyrillic)",
      imeLekLatinica: "Name of medicine (Latin)",
      genericnoIme: "Generic name",
      jacina: "Strength",
      farmacevtskaForma: "Pharmaceutical dosage form",
      kontaktPakuvanje: "Contact packaging",
      pakuvanje: "Packing",
      sostav: "Composition",
      tipMestoProizvodstvo: "Type of place of production",
      mestoProizvodstvo: "Place of production",
      atcKod: "ATC code",
      eanKod: "EAN code",
      pozitivnaLista:"Positive list",
      brailovoPismo: "Braille",
      nacinIzdavanje:"Manner of issuance",
      posebniPredupreduvanja: "Special warnings",
      doziranje: "Dosage",
      datumPrenosDatumArhiva: "Date of transfer",
      brojPrenosArhivskiBroj: "Number of transfer",
      drzava: "State",
      grad: "City",
      nazivMesto: "Name of site",
      adresa: "Address",
      PRESCRIPTION: "ЛЕКОТ МОЖЕ ДА СЕ ИЗДАВА ВО АПТЕКА САМО НА РЕЦЕПТ (Р)",
      HEALTHORGANIZATION: "ЛЕКОТ МОЖЕ ДА СЕ УПОТРЕБУВА САМО ВО ЗДРАВСТВЕНА УСТАНОВА (З)",
      NOPRESCRIPTION: "ЛЕКОТ МОЖЕ ДА СЕ ИЗДАВА ВО АПТЕКА БЕЗ РЕЦЕПТ (БР)",
      ALL: "ЛЕКОТ МОЖЕ ДА СЕ ИЗДАВА И/ИЛИ ПРОДАВА, БЕЗ РЕЦЕПТ (БР*)",
    },
    medicinskiSredstva: {
      osnovniPodatoci: "Basic informations",
      detali: "Details",
      header:"Medical device search form",
      name: "Name",
      GMDNkod: "GMDN code",
      proizvoditel: "Manufacturer",
      nositelOdobrenie: "Customer",
      kategorija: "Category",
      kategorijaNamena: "Category by purpose",
      iscistiFiltri: "Исчисти филтри",
      search: "Search",
      iscistiFiltriButton: "Исчисти",
      naziv: "Name",
      pakuvanje: "Packing",
      nacinIzdavanje: "Manner of issuance",
      datumVaznost: "Date of validity",
      datumResenie: "Date of decision",
      brojResenie: "Number of decision",
      klasa: "Class",
      mestoProizvodstvo: "Place of production",
      grupaProizvodi: "Product group",
      medicinskiSredstva:"Register of medical devices",
      nositel_odobrenie: "Customer",
      nacin_izdavanje: "Manner of issuance",
      gmdn_kod: "GMDN code",
      osnovno_datum_resenie: "Date of decision",
      osnovno_broj_resenie: "Number of decision",
      naziv_kategorija: "Category",
      naziv_klasa:"Class",
      naziv_kategorija_namena: "Category by purpose",
      naziv_grupa:"Product group",
      nazivProdukt: "Product name",
      originalnoIme: "Original name",
      kataloskiBroj: "Catalog number",
    },
    klinickiIspituvanja: {
      header: "Clinical trail",
      klinickiIspituvanja: "Clinical trail",
      brojNaProtokol: "Protocol Number",
      jacina: "Strength",
      jacinaLek: "Medicine strength",
      naslov: "Title",
      naslovIspituvanje: "Study title",
      datumPocetok: "Start Date",
      komitent: "Customer",
      imeLekKirilica: "Name of medicine (Cyrillic)",
      imeLekLatinica: "Name of medicine (Latin)",
      ispituvac: "Investigator",
      name: "Name",
      celNaIspituvanje: "Study objective",
      sponzorIspituvanje: "Sponsor of trial",
      fazaNaIspituvanjeto: "Study phase",
      farmacevtskaDoziranaForma: "Pharmaceutical dosage form",
      glavenIspituvac: "Principal Investigator",
      drzava: "State",
      grad: "City",
      adresa: "Address",
      nazivMesto: "Name of site",
      proizvoditel: "Manufacturer",
      naziv: "Name",
      brojOdobrenie: "Number of approval",
      datumOdobrenie: "Date of approval",
    },
    notifications: {
      dostavuvacTipFailFetch: "Проблем при пребарување на записи за тип на доставувач",
      dostavuvacTipCreateSuccess: "Успешно креирање на тип на доставувач",
      dostavuvacTipFailCreate: "Проблем при креирање на тип на доставувач",
      dostavuvacTipUpdateSuccess: "Успешна промена на тип на доставувач",
      dostavuvacTipFailUpdate: "Проблем при промена на тип на доставувач",
      dostavuvacTipDeleteSuccess: "Успешно бришење на тип на доставувач",
      dostavuvacTipFailDelete: "Проблем при бришење на тип на доставувач",
      periodicReportsFailFetch: "Проблем при пребарување на записи за тип на достава",
      periodicReportsCreateSuccess: "Успешно креирање на тип на достава",
      periodicReportsFailCreate: "Проблем при креирање на тип на достава",
      periodicReportsUpdateSuccess: "Успешна промена на тип на достава",
      periodicReportsFailUpdate: "Проблем при промена на тип на достава",
      periodicReportsDeleteSuccess: "Успешно бришење на тип на достава",
      periodicReportsFailDelete: "Проблем при бришење на тип на достава",
      requestTypeFailFetch: "Проблем при пребарување на записи за тип на барање",
      requestTypeCreateSuccess: "Успешно креирање на тип на барање",
      requestTypeFailCreate: "Проблем при креирање на тип на барање",
      requestTypeUpdateSuccess: "Успешна промена на тип на барање",
      requestTypeFailUpdate: "Проблем при промена на тип на барање",
      requestTypeDeleteSuccess: "Успешно бришење на тип на барање",
      requestTypeFailDelete: "Проблем при бришење на тип на барање",
      baranjeDostavaIzvestajFailFetch: "Проблем при пребарување на записи за барање за достава на извештај",
      baranjeDostavaIzvestajCreateSuccess: "Успешно креирање на барање за достава на извештај",
      baranjeDostavaIzvestajCreateFail: "Проблем при креирање на барање за достана на извештај",
      baranjeDostavaIzvestajUpdateSuccess: "Успешна промена на барање за достава на извештај",
      baranjeDostavaIzvestajUpdateFail: "Проблем при промена на барање за достава на извештај",
      baranjeDostavaIzvestajDeleteSuccess: "Успешно бришење на барање за достава на извештај",
      baranjeDostavaIzvestajDeleteFail: "Проблем при бришење на барање за достава на извештај",
      periodicReportsNotifySuccess: "Успешно испратени известувања до сите назначени коминтенти",
      periodicReportsNotifyFail: "Проблем при испраќање на известувања до сите назначени коминтенти",
      izvestajPotrosuvackaGetBaranjaFail: "Проблем при приказ на барања за достава на извештај за коминтент",
      userInfoFetchFail: "Проблем при пребарување на коминтент и улоги за корисникот односно клиентот кој се најавува",
      izvestajPotrosuvackaSaveFail: "Проблем при зачувување на извештаи за потрошувачка",
      izvestajPotrosuvackaSaveSuccess: "Успешно зачувани извештаи за потрошувачка",
      izvestajPotrosuvackaSubmitSuccess: "Успешно зачувани извештаи за потрошувачка и објавено барање",
      izvestajPotrosuvackaSubmitFail: "Проблем при зачувување на извештаи за потрошувачка и објавување на барање",
      invalidTableDataSave: "Проблем при зачувување на извештаи. Табелата содржи невалидни податоци",
      invalidTableDataSubmit: "Порблем при објавување на барање. Табелата содржи невалидни податоци",
      successCreate: "Успешно креирање на запис",
      failCreate: "Проблем при креирање на запис",
      successDelete: "Успешно избришан запис",
      failDelete: "Проблем при бришење на записот",
      successEdit: "Записот е успешно променет",
      failEdit: "Проблем при промена на записот",
      successSave: "Записот е успешно зачуван",
      failSave: "Записот е неуспешно зачуван",
      conflictingProperty:"Конфликтно поле",
      conflictingValue:"Конфликтна вредност",
      messageId:"ИД на порака",
      constraint:{
        duplicate:"Дупликатен запис",
      },
      "409": "Постои запис за задолжените полиња во зададенатa временска рамка",
      conflict:{
        dateRange: "Записот има датум кој е конфликтен со други записи",
      },
      syncUsers: {
        success: "Успешна синхронизација!",
        fail: "Грешка со серверот!",
      },
      deactivateUsers: {
        success: "Успешно деактивирање на корисникот!",
        fail: "Грешка со серверот!",
      },
      activateUsers: {
        success: "Успешно активирање на корисникот!",
        fail: "Грешка со серверот!",
      },
      group: {
        success: "Успешна операција!",
        fail: "Грешка со серверот!",
      },

      errorRequired: "Полето е задолижително",
      errorSign: "Внесот не е во валиден формат",
      deleteConfirm: "Дали сте сигурни дека сакате да го избришете овој запис?",
      deleteConfirmDostavaType: "Дали сте сигурни дека сакате да го избришете овој запис? Ова може да потрае зависно од тоа колку известувања треба да се поништат.",
      saveConfirm: "Дали сте сигурни дека сакате да го зачувате записот?",
      activateConfirm: "Дали сте сигурни дека саката де го активирате записот?",
      deactivateConfirm:
        "Дали сте сигурни дека сакате да го деактивирате записот?",
      errorLength: "Внесовте повеќе од дозволените 120 карактери",
      errorDate: "'Датум До' не може да биде порано од 'Датум Од'",
      ErrorDate: "Внесовте невалиден датум",
      trim: "Полето не може да започнува, завршува или содржи само празни карактери!",
      ErrorRequired: "Полето е задолжително",
      clearAll: "Исчисти ги сите нотификации",
      empty: "Нема нотификации",
      numbersOnly: "Полето може да содржи само броеви",
    },
  },
};
