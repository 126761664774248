import React from "react";

function Home(props) {
  return (
    <div style={{ textAlign: "center" }}>
      {/* <img src={Logo} width="260px" height="260px" /> */}
      <h1 style={{ textAlign: "center" }}>
        Добредојдовте на <br /> Malmed
      </h1>
    </div>
  );
}
export default Home;
