import {properties} from "../../config/properties";
import {mockUser} from "./mock-user";
import userManager from "./userManager";

export const isAuthenticated = (user) => {
    if(properties.auth.mock)
        user = mockUser;

    return !(!user || user.expired);
};

//user has every permission in the list
export const isAuthorized = (userPermissions, acceptablePermissions) => {


    for(let perm of acceptablePermissions)
    {
        if(userPermissions.indexOf(perm)>=0)
            return true;
    }

    return false;
};

export const isLoadingUser = (oidc) => {

    if(properties.auth.mock)
        return false;

    return oidc.isLoadingUser;
};

export const loginAction = (fRedirectUri) => {
    let wholeUri = window.location.pathname;
    let contextRoot = process.env.REACT_APP_ROUTER_BASE;
    let redirectUri = wholeUri.replace(contextRoot,``);
    sessionStorage.setItem("redirectUri", fRedirectUri?fRedirectUri:redirectUri); //window.location.pathname
    userManager.signinRedirect();
};

