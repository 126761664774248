export default {

    TYPE_TOAST: "TYPE_TOAST",
    LEVEL_LOG: "log",
    LEVEL_INFO: "info",
    LEVEL_WARN: "warn",
    LEVEL_ERROR: "error",
    LEVEL_SUCCESS: "success",

    CLEAR_ALL_NOTIFICATIONS: "CLEAR_ALL_NOTIFICATIONS",
    NO_PERMISSIONS: "NO_PERMISSIONS"
}
