import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import error2 from "../error/error-404.png";
import Box from "@material-ui/core/Box";
import { Button } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";


class Error404Component extends Component  {
    constructor(props) {
        super(props);
    }

    render() {
        return (
    <div>

    <Grid container spacing={2}>
        <Box width="100%" align="center" justifyContent="center" marginTop="15%">
               <img width="20%" src={error2} />
                        <h2>Не постои таква страна во системот! </h2>
                        <Typography variant="caption" color="textSecondary">
                                Кликнете за да се вратите на <Button color="primary" onClick={() => this.props.history.push('/')}>почетната страна</Button>                          
                        </Typography>
                 
        </Box>
     </Grid>
           
    </div>

        );
    }
}
Error404Component.propTypes = {};

export default Error404Component;
