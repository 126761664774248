export default {
  KLINICKI_ISPITUVANJA_FETCH_REQUEST: "KLINICKI_ISPITUVANJA_FETCH_REQUEST",
  KLINICKI_ISPITUVANJA_FETCH_SUCCESS: "KLINICKI_ISPITUVANJA_FETCH_SUCCESS",
  KLINICKI_ISPITUVANJA_FETCH_FAIL: "KLINICKI_ISPITUVANJA_FETCH_FAIL",
  KLINICKI_ISPITUVANJA_CREATE_REQUEST: "KLINICKI_ISPITUVANJA_CREATE_REQUEST",
  KLINICKI_ISPITUVANJA_CREATE_SUCCESS: "KLINICKI_ISPITUVANJA_CREATE_SUCCESS",
  KLINICKI_ISPITUVANJA_CREATE_FAIL: "KLINICKI_ISPITUVANJA_CREATE_FAIL",
  KLINICKI_ISPITUVANJA_UPDATE_REQUEST: "KLINICKI_ISPITUVANJA_UPDATE_REQUEST",
  KLINICKI_ISPITUVANJA_UPDATE_SUCCESS: "KLINICKI_ISPITUVANJA_UPDATE_SUCCESS",
  KLINICKI_ISPITUVANJA_UPDATE_FAIL: "KLINICKI_ISPITUVANJA_UPDATE_FAIL",
  KLINICKI_ISPITUVANJA_DELETE_REQUEST: "KLINICKI_ISPITUVANJA_DELETE_REQUEST",
  KLINICKI_ISPITUVANJA_DELETE_SUCCESS: "KLINICKI_ISPITUVANJA_DELETE_SUCCESS",
  KLINICKI_ISPITUVANJA_DELETE_FAIL: "KLINICKI_ISPITUVANJA_DELETE_FAIL",
  KLINICKI_ISPITUVANJA_RESET: "KLINICKI_ISPITUVANJA_RESET",

  KI_PROIZVODITELI_FETCH_REQUEST: "KI_PROIZVODITELI_FETCH_REQUEST",
  KI_PROIZVODITELI_FETCH_SUCCESS: "KI_PROIZVODITELI_FETCH_SUCCESS",
  KI_PROIZVODITELI_FETCH_FAIL: "KI_PROIZVODITELI_FETCH_FAIL",

  KI_ISPITUVACI_FETCH_REQUEST: "KI_ISPITUVACI_FETCH_REQUEST",
  KI_ISPITUVACI_FETCH_SUCCESS: "KI_ISPITUVACI_FETCH_SUCCESS",
  KI_ISPITUVACI_FETCH_FAIL: "KI_ISPITUVACI_FETCH_FAIL",

  ITEMS_RESET_ARRAY: "ITEMS_RESET_ARRAY:",
};
