import constants from "./constants";
// import {SESSION_TERMINATED, USER_EXPIRED} from "redux-oidc";

const defaultState = {};

export default function reducer(currentState = defaultState, action) {
    switch (action.type) {
        case constants.HEADER_TITLE_CHANGE:
            console.log('reducer activated');
            console.log(action.payload);
            return {
                ...currentState,
                title: action.payload
            };
        default:
            return currentState;
    }
}
