import React, {Component} from 'react';
import userManager from "../userManager";

class LogoutPage extends Component {

    componentDidMount() {
        //userManager.signinRedirect(); // poradi nekoja pricina ne ja krati sso sesijata. Ako se inicira i redirektira na nezastiten link raboti
        //mozebi so popup i callback ke raboti
        //vaka racno funkcionira
        userManager.removeUser();
        userManager.clearStaleState();
        userManager.metadataService.getEndSessionEndpoint().then((signouturl)=>{
            window.location = signouturl+"?post_logout_redirect_uri="+encodeURIComponent(userManager.settings.post_logout_redirect_uri);

        });
    }

    render() {
        return (
            <div>
                Logging out
            </div>
        );
    }
}

export default LogoutPage;
