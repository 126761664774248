import constants from "./constants";

const fetchRequest = (request) => {
  return {
    type: constants.REGISTER_FETCH_REQUEST,
    payload: request,
  };
};

const fetchSuccess = (items) => {
  return {
    type: constants.REGISTER_FETCH_SUCCESS,
    payload: items,
  };
};

const fetchFail = (error) => {
  return {
    type: constants.REGISTER_FETCH_FAIL,
    payload: error,
  };
};
const createRequest = (request) => {
  return {
    type: constants.REGISTER_CREATE_REQUEST,
    payload: request,
  };
};

const createSuccess = (items) => {
  return {
    type: constants.REGISTER_CREATE_SUCCESS,
    payload: items,
  };
};

const createFail = (error) => {
  return {
    type: constants.REGISTER_CREATE_FAIL,
    payload: error,
  };
};

const updateRequest = (request) => {
  return {
    type: constants.REGISTER_UPDATE_REQUEST,
    payload: request,
  };
};

const updateSuccess = (items) => {
  return {
    type: constants.REGISTER_UPDATE_SUCCESS,
    payload: items,
  };
};

const updateFail = (error) => {
  return {
    type: constants.REGISTER_UPDATE_FAIL,
    payload: error,
  };
};

const deleteRequest = (request) => {
  return {
    type: constants.REGISTER_DELETE_REQUEST,
    payload: request,
  };
};

const deleteSuccess = (items) => {
  return {
    type: constants.REGISTER_DELETE_SUCCESS,
    payload: items,
  };
};

const deleteFail = (error) => {
  return {
    type: constants.REGISTER_DELETE_FAIL,
    payload: error,
  };
};

const resetReducer = () => {
  return {
    type: constants.REGISTER_RESET,
    payload: {},
  };
};
const resetItems = () => {
  return {
    type: constants.ITEMS_RESET_ARRAY,
    payload: {},
  };
};

const notifyRequest = (request) => {
  return {
    type: constants.REGISTER_NOTIFY_REQUEST,
    payload: request,
  };
};

const notifySuccess = (item) => {
  return {
    type: constants.REGISTER_NOTIFY_SUCCESS,
    payload: item,
  };
};

const notifyFail = (error) => {
  return {
    type: constants.REGISTER_NOTIFY_FAIL,
    payload: error,
  };
};

export default {
  fetchRequest,
  fetchSuccess,
  fetchFail,
  createRequest,
  createSuccess,
  createFail,
  notifyRequest,
  notifySuccess,
  notifyFail,
  updateRequest,
  updateSuccess,
  updateFail,
  deleteRequest,
  deleteSuccess,
  deleteFail,
  resetReducer,
  resetItems,
};
