import React  from 'react';
import {processSilentRenew} from "redux-oidc";

function SilentRenew(props) {

    processSilentRenew();

    return null;

}
export default (SilentRenew);
