import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MedicinskiSredstvaComponent from "./MedicinskiSredstvaComponent";
import operations from "./duck/operations";
import {Box, Typography, Tabs, Tab, AppBar, makeStyles, withStyles } from "@material-ui/core";
import PropTypes from 'prop-types';
import  {translate} from "../../util/lang/translate-wrapper";
import ListIcon from '@material-ui/icons/List';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faPills, faNotesMedical, faSyringe } from '@fortawesome/free-solid-svg-icons'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box  p={3}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
      border: "2px solid #df4c4c",
      color: "#df4c4c",
  }

}));
function MedicinskiSredstvaContainer(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [selectedItem, setSelectedItem] = useState(undefined); //undefined == none, {} == new
  const { items, loadingItems, processing,} = useSelector((state) => state.registarMedicinskiSredstva);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function fetchItems(item) {
    dispatch(operations.fetchItems(item));
  }

  function reset() {
    dispatch(operations.resetReducer());
  }

  function loadDetails(selectedItem) {
    debugger;
    dispatch(operations.fetchDetails(selectedItem));
  }
  

  return (<>
    <div className={classes.root}>
        <MedicinskiSredstvaComponent
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            items={items}
            fetchItems={fetchItems}
            resetReducer={reset}
            details = {loadDetails}
            // loadingItems={loadingItems}
            // processing={processing}
            /> 
    </div>

     
    </>
  );
}

export default MedicinskiSredstvaContainer;
