import { httprequest } from "asseco-commons";
import { properties } from "../config/properties";
import { createUrlWithPathParams } from "../util/helper/url-paths-params";


export const getKlinickiIspituvanja = () => {
  return httprequest({
    url: `${properties.api.root}/klinicki-ispituvanja`,
    method: "get",
  }).then((response) => Promise.resolve(response));
};

export const searchKlinickiIspituvanja = (formData) => {
  return httprequest({
    url: createUrlWithPathParams(`${properties.api.root}/klinicki-ispituvanja/filter`, formData),
    method: "get",
  }).then((response) => Promise.resolve(response));
};

export const getProizvoditeliById = (idKi) => {
  return httprequest({
    url: `${properties.api.root}/klinicki-ispituvanja/findProizvoditeliByIdKlinicko`,
    method: "get",
    params: {idKlinicko: idKi}
  }).then((response) => Promise.resolve(response));
};

export const getIspituvaciById = (idKi) => {
  return httprequest({
    url: `${properties.api.root}/klinicki-ispituvanja/findIspituvaciByIdKlinicko`,
    method: "get",
    params: {idKlinicko: idKi}
  }).then((response) => Promise.resolve(response));
};