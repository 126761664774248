import React, {useEffect} from 'react';
import userManager from "../userManager";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {translate} from "../../lang/translate-wrapper";
import {loginAction} from "../auth-helper";
import {useSelector} from "react-redux";

function InactivityPage(props) {

    const user = useSelector(state=>state.oidc.user);

    useEffect(()=>{
        if(user)
        {
            //odlogiraj ja momentalnata aplikacija no ne ja odlogiraj celata SSO sesija (zatoa ne se koristi logout)
            userManager.removeUser().then(()=>{
                window.location.reload();
            });
        }
    })
    if(user)
        return <p>redirecting...</p>

    return (
        <body style={{backgroundColor: "#fafafa"}}>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >

            <Grid item xs={3}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {translate("app.inactivity.title")}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {translate("app.inactivity.body")}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" color="primary" onClick={()=>{loginAction("/")}}>
                            {translate("app.inactivity.login")}
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        </body>
    );
}

export default InactivityPage;
