import React, {Component} from 'react';
import {Provider} from "react-redux";
import './App.css';
import store, {history} from "./store";
import {ConnectedRouter} from "connected-react-router";
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles'
import Routes from "./routes";
import {OidcProvider} from "redux-oidc";
import userManager from "./util/auth/userManager";
import _ from 'lodash';
import commonsConfiguration from "asseco-commons/dist/CommonsConfiguration";
import {translate} from "./util/lang/translate-wrapper";
import MomentUtils from '@date-io/moment';
import 'moment/locale/mk';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import { push } from "connected-react-router";
import {properties} from "./config/properties";



const theme = createMuiTheme({
    overrides: {
        MuiTableRow: {
            root: {
                '&:nth-child(odd)': {
                    backgroundColor: '#F4F4F4'
                },
                '&:nth-child(even)': {
                    backgroundColor: '#FEFEFF'
                }
            },
            head: {
                backgroundColor: '#FFFFFF !important'
            },
            footer: {
                backgroundColor: '#FFFFFF !important'
            }
        },
        MuiSelect: {
            root: {
                fontSize: "12px"
            }
        },
        MuiInput: {
            root: {
                fontSize: "12px"
            },

        },
        MuiFormControl: {
            root: {
                fontSize: 12
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: 16
            }
        },
        MuiFormControlLabel: {
            label: {
                fontSize: "12px !important"
            }
        }
    },
    palette: {
        primary: { main: '#902777' },
        secondary: { main: '#1976d2' }
      },
});

class App extends Component {

    componentDidMount() {
        inactivityTimerRefresh();
    }

    render() {
        return (
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider locale={'mk'} utils={MomentUtils} >
                    <OidcProvider store={store} userManager={userManager}>
                    <ConnectedRouter history={history}>
                            <Routes/>
                    </ConnectedRouter>
                    </OidcProvider>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </Provider>
        );
    }
}

//Simulira staromoden timeout. Na otvaranje na ekran i na secure http povik tajmerot se osvezuva
const onInactivityTimerUp = () => {
    console.warn("Inactivity time passed, logging out.");
    store.dispatch(push("/inactivity"));
};
const inactivityTimerRefresh = _.debounce(()=>{if(properties.maxInactivityTime)onInactivityTimerUp()},properties.maxInactivityTime)

export const getToken = function() {
    inactivityTimerRefresh();
    return _.get(store.getState(), "oidc.user.access_token")
}

commonsConfiguration.setup({
    getToken: ()=>getToken(),
    translate: (text)=>translate(text)
});

export default App;
