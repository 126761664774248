import React from "react";
import moment from "moment";

export function renderDate(dateString) {
            var newDateString = moment(dateString).format("DD.MM.YYYY");
        return dateString ? <div>{newDateString}</div> : null;
      }
export function renderDateTextField(dateString) {
        var newDateString = moment(dateString).format("DD.MM.YYYY");
    return dateString ? newDateString : null;
  }